import { useEffect } from 'react'
import ScrollTopRouter from './ScrollTopRouter'
import { useAuth } from '../context/auth/AuthProvider'
import { useLoader } from '../context/loader/LoaderProvider'
import PageApp from '../layout/PageApp'
import ClientRouter from './ClientRouter'
import { useUser } from '../context/user/UserProvider'
import Spinner from '../components/Edenred/spinner/Spinner'
import { openNavigationRoutes } from 'src/config/constants/navigationRoutes'

const isAnOpenRoute = (path: string): boolean =>
  Object.values(openNavigationRoutes).some(route => path === route)

const AppRouter = () => {
  const { isLogged, logIn, user } = useAuth()
  const { userContract } = useUser()
  const { startLoading, stopLoading } = useLoader()

  const openRoute = isAnOpenRoute(location.pathname)

  useEffect(() => {
    const startApp = () => {
      startLoading()
      logIn()
      stopLoading()
    }
    if (!openRoute) startApp()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isLogged) {
      startLoading()
      userContract()
      stopLoading()
    }
  }, [isLogged])

  if (!openRoute && (!isLogged || !user?.code)) {
    return <Spinner />
  }

  return (
    <PageApp>
      <ScrollTopRouter>
        <ClientRouter />
      </ScrollTopRouter>
    </PageApp>
  )
}

export default AppRouter
