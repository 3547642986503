import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import {
  GetDateFormatted,
  dniValidate,
  isPassportFormatValid,
  maxAge,
  minAge,
} from '../../../../../core/helpers'
import { forms } from '../../../../../domain/translations'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { documentType } from 'src/domain/enum'

export const useCardOrderFormSchema = () => {
  const { t } = useTranslation()
  const cardOrderFormSchema = Yup.object().shape({
    employeeData: Yup.object({
      name: Yup.string().required(t(forms.errors.fieldRequired)),
      firstSurname: Yup.string().required(t(forms.errors.fieldRequired)),
      documentTypeId: Yup.number().min(1, t(forms.errors.fieldRequired)),
      document: Yup.string()
        .required(t(forms.errors.fieldRequired))
        .test('dniTestValidate', t(forms.errors.invalidFormat), (value, ctx) => {
          let isValid = true
          if (ctx.parent.documentTypeId < documentType.Passport) {
            isValid = dniValidate(value)
          }
          if (ctx.parent.documentTypeId === documentType.Passport && value) {
            return isPassportFormatValid(value)
          }
          return isValid
        }),
      birthDate: Yup.string()
        .required(t(forms.errors.fieldRequired))
        .test(
          'validateMinDateBirth',
          t(cardOrderTranslation.form.errors.validateMinDateBirth) +
            ' ' +
            GetDateFormatted(maxAge()),
          value => {
            if (value) {
              const maximumAge = maxAge()
              return (Date.parse(maximumAge) <= Date.parse(value)) as boolean
            }
            return true
          }
        )
        .test(
          'validateMaxDateBirth',
          t(cardOrderTranslation.form.errors.validateMaxDateBirth) +
            ' ' +
            GetDateFormatted(minAge()),
          value => {
            if (value) {
              const minimumAge = minAge()
              return (Date.parse(minimumAge) >= Date.parse(value)) as boolean
            }
            return true
          }
        ),
      email: Yup.string()
        .email(t(forms.errors.invalidFormat))
        .required(t(forms.errors.fieldRequired)),
      telephone: Yup.string().test(
        'validatePhone',
        t(forms.errors.phoneInvalid),
        value => {
          if (value && value.length > 0)
            return /(6|7|8|9)[ -]*([0-9][ -]*){8}$/.test(value)
          return true
        }
      ),
    }),
    cardData: Yup.object({
      cardRequestTypeId: Yup.number().min(1, t(forms.errors.fieldRequired)),
      spendingRuleId: Yup.number().min(1, t(forms.errors.fieldRequired)),
      initialAmount: Yup.string()
        .nullable()
        .test('validateNumber', t(forms.errors.numberValue), value => {
          if (value && value.length > 0) {
            const tryParseInt = parseInt(value)
            return isNaN(tryParseInt) ? false : true
          }
          return true
        })
        .test('validateNumberNegative', t(forms.errors.numberValue), value => {
          if (value && value.length > 0) {
            const tryParseInt = parseInt(value)
            if (!isNaN(tryParseInt)) return tryParseInt < 0 ? false : true
          }
          return true
        })
        .test(
          'validateMaxAmount',
          t(cardOrderTranslation.form.errors.maxInitialAmount),
          value => {
            if (value && value.length > 0) {
              const tryParseInt = parseInt(value)
              if (!isNaN(tryParseInt)) return tryParseInt > 5000 ? false : true
            }
            return true
          }
        )
        .test(
          'testDecimalValidate',
          t(cardOrderTranslation.form.errors.amountTwoDecimalsMax),
          value => {
            // eslint-disable-next-line no-useless-escape
            if (value) {
              const valueToString = value.split('.' || ',')[1]
              if (valueToString) {
                return !(valueToString.length > 2) as boolean
              }
            }
            return true
          }
        ),
    }),
    sendData: Yup.object({
      deliverySiteId: Yup.string().test(
        'fieldRequired',
        t(forms.errors.fieldRequired),
        value => {
          if (value && +value > 0) return true
          return false
        }
      ),
    }),
  })
  return { cardOrderFormSchema }
}
