import { OreStack } from '@runroom/oreneta'
import { useEffect, useRef, useState } from 'react'
import { LogaltyModel } from '../../domain'
import { openNavigationRoutes } from 'src/config/constants/navigationRoutes'

interface LogaltyIframeProps {
  logalty: LogaltyModel
  callback: () => void
}

export const LogaltyIframe = ({ logalty, callback }: LogaltyIframeProps): JSX.Element => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const createForm = (): string | undefined => {
    if (!logalty?.signUrl || !logalty?.signSAML) {
      console.error('Logalty details are missing')
      return undefined
    }

    const formHTML = `
      <form method="post" action="${logalty.signUrl}">
        <input type="hidden" name="saml_assertion" value="${encodeURIComponent(
          logalty.signSAML
        )}">
      </form>
    `
    return formHTML
  }

  useEffect(() => {
    if (iframeLoaded) {
      const iframe = iframeRef.current

      if (iframe?.contentWindow) {
        const form = createForm()
        if (form) {
          iframe.contentWindow.document.open()
          iframe.contentWindow.document.write(form)
          iframe.contentWindow.document.close()
          iframe.contentWindow.document.forms[0].submit()
        }
      }
    }
  }, [iframeLoaded, logalty])

  const checkIframeActivity = (): void => {
    if (iframeLoaded) {
      const iframe = iframeRef.current
      if (iframe) {
        if (
          iframe.contentDocument !== null &&
          iframe.contentDocument?.location?.href &&
          iframe.contentDocument.location.href.includes(
            openNavigationRoutes.logaltyCheckStatus
          )
        ) {
          console.log('Logalty iframe has finished')
          iframe.remove()
          console.log('Logalty iframe has been removed')
          callback()
        }
      }
    }
  }

  return (
    <OreStack placeContent="stretch" space="large">
      <iframe
        ref={iframeRef}
        width="100%"
        height="1090px"
        onLoad={() => {
          checkIframeActivity()
          setIframeLoaded(true)
        }}
        srcDoc={iframeLoaded ? createForm() : ''}
      />
    </OreStack>
  )
}
