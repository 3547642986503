import { Outlet } from 'react-router-dom'
import { SimplePageApp } from './SimplePageApp'

export const SimpleAppLayout = (): JSX.Element => {
  return (
    <SimplePageApp hasLanguages>
      <Outlet />
    </SimplePageApp>
  )
}
