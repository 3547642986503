import { useTranslation } from 'react-i18next'
import {
  GetDateFormatted,
  dniValidate,
  isPassportFormatValid,
  maxChildAgeSchoolYear,
  minChildAge,
} from 'src/core/helpers'
import { currency, documentType } from 'src/domain/enum'
import { forms, spendingRuleFormTranslation } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import * as Yup from 'yup'

export const useTGDOrderFormSchema = () => {
  const { t } = useTranslation()

  const minAmount = 0.1
  const maxAmount = 5000

  const TGDOrderFormChildSchema = Yup.object({
    firstName: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(20, t(forms.errors.maxAllowed)),
    lastName: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(20, t(forms.errors.maxAllowed)),
    lastName2: Yup.string().max(75, t(forms.errors.maxAllowed)),
    kindergartenId: Yup.number()
      .notOneOf([0], t(forms.errors.fieldRequired))
      .required(t(forms.errors.fieldRequired)),
    birthDate: Yup.date()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .required(t(forms.errors.fieldRequired))
      .max(
        new Date(minChildAge()),
        t(TGDOrderTranslation.form.child.errors.validateMaxDateBirth) +
          GetDateFormatted(minChildAge())
      )
      .test(
        'validateMinDateBirth',
        t(TGDOrderTranslation.form.child.errors.validateMinDateBirth) +
          GetDateFormatted(maxChildAgeSchoolYear(6)),
        value => {
          if (value) {
            const maximumAge = new Date(maxChildAgeSchoolYear(6))
            const maximunAgeNew = maximumAge.setDate(maximumAge.getDate() - 1)
            return (new Date(maximunAgeNew) <= value) as boolean
          }
          return true
        }
      ),
    initialAmount: Yup.number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .required(t(forms.errors.fieldRequired))
      .min(
        minAmount,
        `${t(spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual)} ${
          minAmount + currency.euro
        }`
      )
      .max(
        maxAmount,
        `${t(spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual)}  ${
          maxAmount + currency.euro
        }`
      )
      .test(
        'decimal-max-2-places',
        t(spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals),
        value => {
          const isNullable = value === null || value === undefined
          if (isNullable) {
            return true
          }
          const regex = /^\d+(\.\d{1,2})?$/
          return regex.test(value?.toString())
        }
      ),
  })

  const TGDOrderFormSchema = Yup.object({
    name: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(20, t(forms.errors.maxAllowed)),
    firstSurname: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(20, t(forms.errors.maxAllowed)),
    secondSurname: Yup.string().max(20, t(forms.errors.maxAllowed)),
    documentTypeId: Yup.number()
      .transform(value => (Number.isNaN(value) ? null : value))
      .nullable()
      .required(t(forms.errors.fieldRequired))
      .min(1, t(forms.errors.fieldRequired)),
    document: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .max(14, t(forms.errors.maxAllowed))
      .test('dniTestValidate', t(forms.errors.invalidFormat), (value, ctx) => {
        let isValid = true
        if (ctx.parent.documentTypeId < documentType.Passport) {
          isValid = dniValidate(value)
        }
        if (ctx.parent.documentTypeId === documentType.Passport && value) {
          return isPassportFormatValid(value)
        }
        return isValid
      }),
    email: Yup.string()
      .email(t(forms.errors.invalidFormat))
      .required(t(forms.errors.fieldRequired)),
    telephone: Yup.string().test('validatePhone', t(forms.errors.phoneInvalid), value => {
      if (value && value.length > 0) return /(6|7|8|9)[ -]*([0-9][ -]*){8}$/.test(value)
      return true
    }),
    costCenter: Yup.string().nullable().max(50, t(forms.errors.maxAllowed)),
    employeeNumber: Yup.string().nullable().max(10, t(forms.errors.maxAllowed)),
    childs: Yup.array().of(TGDOrderFormChildSchema).required(),
  })

  const TGDOrderChildOnlyFormSchema = Yup.object({
    childs: Yup.array().of(TGDOrderFormChildSchema).required(),
  })

  return {
    TGDOrderFormSchema,
    TGDOrderChildOnlyFormSchema,
  }
}
