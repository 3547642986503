import { LogaltyResponseStatus } from '../../Logalty/domain'
import { LogaltyStatusChecker } from '../../Logalty/ui/organisms'
import { ErrorScreen } from '../atoms/ErrorScreen'
import { PendingScreen } from '../atoms/PendingScreen'
import { SuccessScreen } from '../atoms/SuccessScreen'
import { autoenrollmentStandarizationProcessStatus } from 'src/domain/enum/autoenrollment'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'

const renderState = (state: LogaltyResponseStatus): JSX.Element => {
  switch (state) {
    case LogaltyResponseStatus.Pending:
      return <PendingScreen />
    case LogaltyResponseStatus.Success:
      return <SuccessScreen />
    case LogaltyResponseStatus.Error:
      return <ErrorScreen />
    default:
      return <PendingScreen />
  }
}

export const RoleStandarizationStep2 = (): JSX.Element => {
  const { logalty } = useRoleStandarization()
  const { getSignStatus, signStatus } = logalty

  console.log('signStatus', signStatus)

  return (
    <LogaltyStatusChecker
      logalty={logalty}
      currentModel={''}
      getSignStatus={() =>
        getSignStatus(autoenrollmentStandarizationProcessStatus.logaltySignatureStarted)
      }>
      {renderState(signStatus)}
    </LogaltyStatusChecker>
  )
}
