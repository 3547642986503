export const productAdherenceTranslations = {
  title: 'flex.products.adherence.title',
  subtitleProducts: 'flex.products.adherence.subtitle-prod',
  tabProd: 'flex.products.adherence.tab-prod',
  table: {
    noProducts: 'flex.products.adherence.table.no_products',
    title: 'flex.products.adherence.table.title',
    header: {
      name: 'flex.products.adherence.table.header.name',
      description: 'flex.products.adherence.table.header.description',
      totalAmount: 'flex.products.adherence.table.header.total_amount',
      applicationDate: 'flex.products.adherence.table.header.application_date',
      action: 'flex.products.adherence.table.header.action',
      tooltip: 'flex.products.adherence.table.header.tooltip',
    },
    row: {
      months: 'flex.products.adherence.table.row.months',
      oneMonth: 'flex.products.adherence.table.row.one_month',
      seeMore: 'flex.products.adherence.table.row.seeMore',
      actions: {
        declineLabel: 'flex.products.adherence.table.row.actions.decline_label',
        acceptLabel: 'flex.products.adherence.table.row.actions.accept_label',
      },
    },
  },
  modal: {
    title: 'flex.products.adherence.modal.title',
    description: 'flex.products.adherence.modal.description',
    textarea: {
      label: 'flex.products.adherence.modal.textarea.label',
      placeholder: 'flex.products.adherence.modal.textarea.placeholder',
    },
  },
  notification: {
    accepted: 'flex.products.adherence.notification.accepted',
    rejected: 'flex.products.adherence.notification.rejected',
  },
  pending: 'flex.products.adherence.pending',
  noPending: 'flex.products.adherence.no_pending',
  videoTutorial: {
    accept: 'flex.products.adherence.videoTutorial.accept',
    check: 'flex.products.adherence.videoTutorial.check',
  },
}
