import { OreButton, OreHeading, OreText, SvgArrowLeft, SvgSave } from '@runroom/oreneta'
import { currency } from '../../../../../domain/enum/cardOrder'
import { forms } from '../../../../../domain/translations'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { Divider } from '../../../../layout'
import { TGDOrderCheckout } from '../molecules/TGDOrderCheckout'
import { TGDOrderCheckoutHeader } from '../molecules/TGDOrderCheckoutHeader'
import { TGDOrderSaveAndClose } from '../../TGDSaveAndCloseModal/organism/TGDOrderSaveAndClose'
import { Modal } from 'src/presentation/components/Edenred'
import { useTGDOrderCheckout } from '../hooks/useTGDOrderCheckout'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTGDOrder } from '../../context/TGDOrderProvider'

export const TGDOrderCheckoutContainer = (): JSX.Element => {
  const location = useLocation()
  const data: any = location.state
  const {
    setShowCancelModal,
    showCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    onCompleteOrder,
    showSaveCloseModal,
    setShowSaveCloseModal,
    tgdOrderCheckout,
    tgdSummaryForm,
  } = useTGDOrderCheckout()
  const { tgdOrders } = useTGDOrder()
  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!tgdOrderCheckout) return <></>

  return (
    <>
      <TGDOrderCheckoutHeader
        detail={{
          paymentMode: tgdOrderCheckout.data
            ? tgdOrderCheckout.data.detail.paymentMode
            : '',
          paymentDescription: tgdOrderCheckout.data
            ? tgdOrderCheckout.data.detail.paymentDescription
            : '',
          paymentTooltip: tgdOrderCheckout.data
            ? tgdOrderCheckout.data.detail.paymentTooltip
            : '',
          initialChargeDate: tgdSummaryForm.initialChargeDate
            ? tgdSummaryForm.initialChargeDate
            : tgdOrderCheckout.data?.detail.initialChargeDate,
          billReference: tgdSummaryForm.billReference
            ? tgdSummaryForm.billReference
            : tgdOrderCheckout.data?.detail.billReference,
        }}
      />
      <TGDOrderCheckout
        data={tgdOrderCheckout.data?.charge}
        quantity={tgdOrderCheckout.data?.charge.quantity}
        title={t(TGDOrderTranslation.checkout.assignmentTitle)}
      />
      <section
        className="width-max"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <OreHeading as="h4" size="headline-md">
          {t(cardOrderTranslation.cardOrderResume.totalAmount)}
        </OreHeading>
        <OreHeading as="h4" size="headline-md">
          {tgdOrderCheckout.data?.totalAmount + currency.euro}
        </OreHeading>
      </section>

      <section className="width-max">
        <Divider />
        <div className="flex flex--row mt-2 space-between">
          <div className="flex flex--row">
            <>
              <OreButton
                as="button"
                category="highlight"
                onClick={() => navigate(-1)}
                size="large"
                style={{ color: '#0F172A' }}
                icon={<SvgArrowLeft />}>
                {t(forms.buttons.goBack)}
              </OreButton>
              {tgdOrders && tgdOrders.length > 0 ? (
                <OreButton
                  category="tertiary"
                  icon={<SvgSave />}
                  onClick={() => setShowSaveCloseModal(true)}
                  size="large">
                  {t(forms.buttons.saveAndClose)}
                </OreButton>
              ) : (
                <div></div>
              )}
            </>
          </div>

          <div className="flex flex--row float-right">
            <OreButton
              as="button"
              category="secondary"
              onClick={() => onClickCancel()}
              size="large">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              onClick={() => onCompleteOrder(data ? data.isExcel : false)}
              size="large">
              {t(cardOrderTranslation.cardOrderResume.confirmOrder)}
            </OreButton>
          </div>
        </div>
      </section>

      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>

      <TGDOrderSaveAndClose
        setShowSaveCloseModal={setShowSaveCloseModal}
        showSaveCloseModal={showSaveCloseModal}
      />
    </>
  )
}
