export const flexHomeTranslations = {
  title: 'flexHomeTranslation.title',
}

export const flexProductsTranslations = {
  title: 'flex.products.title',
  onboarding: 'flex.products.onboarding',
  success: 'flex.products.save.success',
  videoTutorial: {
    products: 'flex.products.video-tutorial.products',
    external: 'flex.products.video-tutorial.external',
    training: 'flex.products.video-tutorial.training',
  },
  table: {
    title: 'flex.products.table.title',
    header: {
      name: 'flex.products.table.header.name',
      description: 'flex.products.table.header.description',
      status: 'flex.products.table.header.status',
      details: 'flex.products.table.header.details',
      active: 'flex.products.table.header.active',
      actions: 'flex.products.table.header.actions',
    },
    row: {
      status: {
        active: 'flex.products.table.row.status.active',
        inactive: 'flex.products.table.row.status.inactive',
      },
      description: {
        cta: 'flex.products.table.row.description.cta',
      },
      details: {
        cardType: 'flex.products.table.row.details.card_type',
        plasctic: 'flex.products.table.row.details.plastic',
        virtual: 'flex.products.table.row.details.virtual',
        startDate: 'flex.products.table.row.details.start_date',
        monthlyLimit: 'flex.products.table.row.details.monthly_limit',
        months: 'flex.products.table.row.details.months',
        yearLimit: 'flex.products.table.row.details.year_limit',
        maxAmount: 'flex.products.table.row.details.max_amount',
        monthNumber: 'flex.products.table.row.details.month_number',
      },
      actions: {
        edit: 'flex.products.table.row.actions.edit',
        edit_config: 'flex.products.table.row.actions.edit_config',
        active: 'flex.products.table.row.actions.active',
        download_tooltip: 'flex.products.table.row.actions.download_tooltip',
      },
    },
    bottom: {
      legend: 'flex.products.table.bottom.legend',
    },
    noProducts: 'flex.products.table.no_products',
  },
  toggle: {
    form: {
      title: 'flex.products.toggle.form.title',
      paragraph0: 'flex.products.toggle.form.paragraph_0',
      paragraph1: 'flex.products.toggle.form.paragraph_1',
      paragraph2: 'flex.products.toggle.form.paragraph_2',
    },
  },
  configure: {
    common: {
      collectivesWarning: 'flex.configure.common.collectives_warning',
      collectivesWarningMain: 'flex.configure.common.collectives_warning_main',
      collectivesChanges: 'flex.configure.common.collectives_changes',
      submit: 'flex.configure.common.submit',
      close: 'flex.configure.common.close',
      form: {
        startDate: {
          label: 'flex.configure.common.form.start_date.label',
          legend: 'flex.configure.common.form.start_date.legend',
          disabledLegend: 'flex.configure.common.form.disabled_start_date.label',
          tooltip: 'flex.configure.common.form.start_date.tooltip',
        },
        monthlyLimit: {
          label: 'flex.configure.common.form.monthly_limit.label',
        },
        yearlyLimit: {
          label: 'flex.configure.common.form.yearly_limit.label',
        },
      },
      productTypology: 'flex.configure.common.product_typology',
      productTypologyTypes: {
        virtual: 'flex.configure.common.product_typology_types.virtual',
        physical: 'flex.configure.common.product_typology_types.physical',
      },
    },
    ticketRestaurant: {
      title: 'flex.configure.ticket_restaurant.title',
      subtitle: 'flex.configure.ticket_restaurant.subtitle',
      form: {
        months: {
          label: 'flex.configure.ticket_restaurant.form.months.label',
          legend: 'flex.configure.ticket_restaurant.form.months.legend',
        },
        monthlyLimit: {
          legend: 'flex.configure.ticket_restaurant.form.monthly_limit.legend',
          placeholder: 'flex.configure.ticket_restaurant.form.monthly_limit.placeholder',
        },
        activate: {
          title: 'flex.configure.ticket_restaurant.form.activate.title',
          subtitle: 'flex.configure.ticket_restaurant.form.activate.subtitle',
          activate: 'flex.configure.ticket_restaurant.form.activate.activate',
          deactivate: 'flex.configure.ticket_restaurant.form.activate.deactivate',
        },
      },
    },
    ticketGuarderia: {
      title: 'flex.configure.ticket_guarderia.title',
      subtitle: 'flex.configure.ticket_guarderia.subtitle',
      form: {
        months: {
          label: 'flex.configure.ticket_guarderia.form.months.label',
        },
        monthlyLimit: {
          legend: 'flex.configure.ticket_guarderia.form.monthly_limit.legend',
        },
      },
    },
    ticketTransporte: {
      title: 'flex.configure.ticket_transport.title',
      subtitle: 'flex.configure.ticket_transport.subtitle',
      form: {
        months: {
          label: 'flex.configure.ticket_transport.form.months.label',
          legend: 'flex.configure.ticket_transport.form.months.legend',
        },
        monthlyLimit: {
          legend: 'flex.configure.ticket_transport.form.monthly_limit.legend',
          placeholder: 'flex.configure.ticket_transport.form.monthly_limit.placeholder',
        },
      },
    },
    formation: {
      title: 'flex.configure.formation.title',
      subtitle: 'flex.configure.formation.subtitle',
      employees_1: 'flex.configure.formation.employees_1',
      employee_2: 'flex.configure.formation.employee_2',
      employees_2: 'flex.configure.formation.employees_2',
      employees_3: 'flex.configure.formation.employees_3',
      description_school: 'flex.configure.formation.description_school',
      description_noSchool: 'flex.configure.formation.description_noSchool',
      description2: 'flex.configure.formation.description2',
      form: {
        priceLimit: {
          label: 'flex.configure.formation.form.price_limit.label',
          legend: 'flex.configure.formation.form.price_limit.legend',
        },
        months: {
          label: 'flex.configure.formation.form.months.label',
          legend: 'flex.configure.formation.form.months.legend',
          error: 'flex.configure.formation.form.months.error',
          warning: 'flex.configure.formation.form.months_warning',
        },
        manual: {
          label: 'flex.configure.formation.form.manual.label',
        },
        canBeLower: {
          label: 'flex.configure.formation.form.can_be_lower.label',
          yes: 'flex.configure.formation.form.can_be_lower.yes',
          no: 'flex.configure.formation.form.can_be_lower.no',
        },
        school: {
          generalDescription: 'flex.configure.formation.form.school.general_description',
          type: {
            label: 'flex.configure.formation.form.school.type.label',
            legend: 'flex.configure.formation.form.school.type.legend',
            firstValue: 'flex.configure.formation.form.school.type.first_value',
          },
          course: 'flex.configure.formation.form.school.course',
          courses: 'flex.configure.formation.form.school.courses',
          show: 'flex.configure.formation.form.school.show',
          hide: 'flex.configure.formation.form.school.hide',
        },
        course: {
          description: 'flex.configure.formation.form.course.description',
          downloadInfo: 'flex.configure.formation.form.course.download_info',
        },
      },
    },
    healthExternal: {
      title: 'flex.configure.health_external.title',
      explanation: 'flex.configure.health_external.explanation',
      subtitle: 'flex.configure.health_external.subtitle',
      no_remove_last: 'flex.configure.health_external.no_remove_last',
      update_warning: 'flex.configure.health_external.update_warning',
      save_changes: 'flex.configure.health_external.save_changes',
      delete_policy: 'flex.configure.health_external.policy.delete',
      force_delete_policy: 'flex.configure.health_external.policy.force_delete',
      force_delete_policy_message1:
        'flex.configure.health_external.policy.force_delete_message1',
      force_delete_policy_message2:
        'flex.configure.health_external.policy.force_delete_message2',
      force_delete_policy_message3:
        'flex.configure.health_external.policy.force_delete_message3',
      force_delete_policy_message4:
        'flex.configure.health_external.policy.force_delete_message4',
      force_delete_policy_message5:
        'flex.configure.health_external.policy.force_delete_message5',
      force_delete_policy_message6:
        'flex.configure.health_external.policy.force_delete_message6',
      form: {
        policies: {
          label: 'flex.configure.health_external.form.policies.label',
          error: 'flex.configure.health_external.form.policies.error',
        },
        company: {
          label: 'flex.configure.health_external.form.company.label',
        },
        policyName: {
          label: 'flex.configure.health_external.form.policy_name.label',
        },
        monthlyImport: {
          label: 'flex.configure.health_external.form.monthly_import.label',
          legend: 'flex.configure.health_external.form.monthly_import.legend',
        },
        phone: {
          label: 'flex.configure.health_external.form.phone.label',
        },
        address: {
          label: 'flex.configure.health_external.form.address.label',
        },
        actions: {
          add: 'flex.configure.health_external.form.actions.add',
          delete: 'flex.configure.health_external.form.actions.delete',
        },
        total_contracts: {
          text1: 'flex.configure.health_external.total_contracts.text1',
          text2: 'flex.configure.health_external.total_contracts.text2',
          text3: 'flex.configure.health_external.total_contracts.text3',
        },
      },
    },
    health: {
      title: 'flex.configure.health.title',
      subtitle: 'flex.configure.health.subtitle',
      warning: 'flex.configure.health.warning',
      form: {
        error: {
          noPolicies: 'flex.configure.health.form.policies.error.no_policies',
        },
      },
      table: {
        header: {
          company: 'flex.configure.health.table.header.company',
          modality: 'flex.configure.health.table.header.modality',
          monthlyImport: 'flex.configure.health.table.header.monthly_import',
          conditions: 'flex.configure.health.table.header.conditions',
        },
        tooltip: 'flex.configure.health.table.tooltip',
        legend: 'flex.configure.health.table.legend',
      },
      isalud: {
        pre: 'flex.configure.health.isalud.pre',
        post: 'flex.configure.health.isalud.post',
        tlf: 'flex.configure.health.isalud.tlf',
      },
      toggle: {
        open: {
          title: 'flex.configure.health.toggle.open.title',
          paragraph_0: 'flex.configure.health.toggle.open.paragraph_0',
          paragraph_1: 'flex.configure.health.toggle.open.paragraph_1',
        },
        close: {
          paragraph_0: 'flex.configure.health.toggle.close.paragraph_0',
          paragraph_1: 'flex.configure.health.toggle.close.paragraph_1',
        },
      },
    },
  },
  ids: {
    7: 'flex.products.ids.7',
    16: 'flex.products.ids.16',
    19: 'flex.products.ids.19',
    23: 'flex.products.ids.23',
    98: 'flex.products.ids.98',
    99: 'flex.products.ids.99',
  },
  cutOffDate: {
    title: 'flex.cut_off_date.title',
    legend: 'flex.cut_off_date.legend',
    form: {
      label: 'flex.cut_off_date.form.label',
      placeholder: 'flex.cut_off_date.form.placeholder',
      button: 'flex.cut_off_date.form.button',
      monthsError: 'flex.cut_off_date.form.monthsError',
    },
    notification: 'flex.cut_off_date.notification',
  },
  download: {
    title: 'flex.products.download.title',
    subtitle: 'flex.products.download.subtitle',
    success: 'flex.products.download.success',
    contract_button: 'flex.products.download.contract_button',
    certificate_button: 'flex.products.download.certificate_button',
  },
}
