export const orderImputationsTranslations = {
  title: 'flex.orders.imputation.title',
  description: 'flex.orders.imputation.description',
  nextMonth: 'flex.orders.imputation.next_month',
  calc_button: 'flex.orders.imputation.calc_button',
  noOrders: {
    description: 'flex.orders.imputation.no_orders.description',
    nextMonth: 'flex.orders.imputation.no_orders.next_month',
  },
  table: {
    title: 'flex.orders.imputation.table.title',
    title_formation: 'flex.orders.imputation.table.title_formation',
    title_external_health: 'flex.orders.imputation.table.title_external_health',
    header: {
      owner: 'flex.orders.imputation.table.header.owner',
      description: 'flex.orders.imputation.table.header.description',
      monthlyImport: 'flex.orders.imputation.table.header.monthly_import',
      remaining: 'flex.orders.imputation.table.header.remaining',
      total: 'flex.orders.imputation.table.header.total',
      actions: 'flex.orders.imputation.table.header.actions',
    },
    headerTooltips: {
      monthlyImport: 'flex.orders.imputation.table.header.tooltips.monthly_import',
      remaining: 'flex.orders.imputation.table.header.tooltips.remaining',
    },
    noOrders: 'flex.orders.imputation.table.no_orders',
    noOrdersWithFilters: 'flex.orders.imputation.table.no_orders_with_filters',
    cta: 'flex.orders.imputation.table.cta',
    body: {
      months: 'flex.orders.imputation.table.body.months',
    },
  },
  configure: {
    delete: {
      title: 'flex.orders.imputation.configure.delete.title',
      description: 'flex.orders.imputation.configure.delete.description',
      notification: 'flex.orders.imputation.configure.delete.notification',
      checkImputationTooltip:
        'flex.orders.imputation.configure.delete.checkImputationTooltip',
      uncheckImputationTooltip:
        'flex.orders.imputation.configure.delete.uncheckImputationTooltip',
      removeImputationTooltip:
        'flex.orders.imputation.configure.delete.removeImputationTooltip',
    },
    impute: {
      title: 'flex.orders.imputation.configure.impute.title',
      title_salud: 'flex.orders.imputation.configure.impute.title_salud',
      amount: 'flex.orders.imputation.configure.impute.amount',
      amount_salud: 'flex.orders.imputation.configure.impute.amount_salud',
      description: 'flex.orders.imputation.configure.impute.description',
      description_salud: 'flex.orders.imputation.configure.impute.description_salud',
      notification: 'flex.orders.imputation.configure.impute.notification',
    },
    externalInsurance: {
      subtitleInactive:
        'flex.orders.imputation.configure.externalInsurance.subtitleInactive',
      inactiveText1_1:
        'flex.orders.imputation.configure.externalInsurance.inactiveText1_1',
      inactiveText1_2:
        'flex.orders.imputation.configure.externalInsurance.inactiveText1_2',
      inactiveText1_3:
        'flex.orders.imputation.configure.externalInsurance.inactiveText1_3',
      inactiveText2: 'flex.orders.imputation.configure.externalInsurance.inactiveText2',
    },
    formation: {
      subtitleInactive: 'flex.orders.imputation.configure.formation.subtitleInactive',
      inactiveText1_1: 'flex.orders.imputation.configure.formation.inactiveText1_1',
      inactiveText1_2: 'flex.orders.imputation.configure.formation.inactiveText1_2',
      inactiveText1_3: 'flex.orders.imputation.configure.formation.inactiveText1_3',
      inactiveText2: 'flex.orders.imputation.configure.formation.inactiveText2',
    },
  },
  list: {
    text1: 'flex.orders.imputation.list_text1',
    text2: 'flex.orders.imputation.list_text2',
    text3: 'flex.orders.imputation.list_text3',
  },
  stepTwo: {
    label1: 'flex.orders.imputation.steptwo.label1',
    label2: 'flex.orders.imputation.steptwo.label2',
    label3: 'flex.orders.imputation.steptwo.label3',
  },
  stepThree: {
    success: {
      title: 'flex.orders.imputation.stepthree.success.title',
      description: 'flex.orders.imputation.stepthree.success.description',
      check_payroll: 'flex.orders.imputation.stepthree.success.check_payroll',
    },
  },
  emptyRows: {
    title: 'flex.orders.imputation.empty_rows.title',
    description1: 'flex.orders.imputation.empty_rows.description1',
    description2: 'flex.orders.imputation.empty_rows.description2',
    description3: 'flex.orders.imputation.empty_rows.description3',
    go_payroll: 'flex.orders.imputation.empty_rows.go_payroll',
    payroll_legend: 'flex.orders.imputation.empty_rows.payroll_legend',
  },
  forceUpdate: {
    title: 'flex.orders.imputation.forceUpdate.title',
    description: 'flex.orders.imputation.forceUpdate.description',
    button: 'flex.orders.imputation.forceUpdate.button',
    updateDate: 'flex.orders.imputation.forceUpdate.updateDate',
  },
  videotutorial: {
    text: 'flex.orders.imputation.videotutorial.text',
  },
}
