import { useState } from 'react'
import { RoleStandarizationContext } from './RoleStandarizationContext'
import { useRoleStandarizationController } from 'src/presentation/components/roleStandarization/hooks/useRoleStandarizationController'
import { autoenrollmentService } from 'src/core/services/AutoenrollmentService'
import { HttpModel } from 'src/domain/models'
import { LogaltyResponseStatus } from 'src/presentation/components/Logalty/domain'
import { AutoEnrollmentTypes } from 'src/domain/enum/autoenrollment'
import { useLogaltyFirmController } from 'src/presentation/components/Logalty/ui/hooks'

// Create a provider component
export const RoleStandarizationProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const controller = useRoleStandarizationController()

  const [file, setFile] = useState<string>('')
  const [showModalAndWarning, setShowModalAndWarning] = useState<boolean>(false)

  const getSignStatusFunction = (): Promise<HttpModel<LogaltyResponseStatus>> => {
    return autoenrollmentService().GetAutoEnrollmentLogaltyStatus(
      AutoEnrollmentTypes.autoEnrollment
    )
  }
  const logalty = useLogaltyFirmController(getSignStatusFunction)

  const handleSetFile = (fileContent: string) => {
    setFile(fileContent)
  }

  const handleShowModalAndWarning = (show: boolean) => {
    setShowModalAndWarning(show)
  }

  return (
    <RoleStandarizationContext.Provider
      value={{
        file,
        handleSetFile,
        showModalAndWarning,
        handleShowModalAndWarning,
        logalty,
        ...controller,
      }}>
      {children}
    </RoleStandarizationContext.Provider>
  )
}
