import { PageWrapper } from 'src/presentation/layout'
import { Header } from '../Edenred/navigation'
import { RoleStandarizationContainer } from './organism/RoleStandarizationContainer'

export const RoleStandarizationPage = (): JSX.Element => {
  return (
    <>
      <Header noSidebar />
      <main className="main-page">
        <PageWrapper>
          <RoleStandarizationContainer />
        </PageWrapper>
      </main>
    </>
  )
}
