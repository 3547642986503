export enum autoenrollmentStandarizationProcessStatus {
  /// Nunca debería tener este estado
  defaultStatus = 0,
  /// Proceso inciado
  initiated = 1,
  /// Ha rellenado la información de firmante
  signerDataObtained = 3,
  /// Ha iniciado la firma con logalty
  logaltySignatureStarted = 100,
  /// La firma es correcta
  logaltySignatureFinishedSuccessfully = 101,
  /// La firma es es incorrecta
  logaltySignatureFinishedUnsuccessfully = 102,
  /// Estado final, el proceso ha acabado correctamente
  processEnded = 200,
  /// Estado final, el proceso ha sido abortado
  processAborted = 201,
}

export enum AutoEnrollmentTypes {
  defualtProcessType = 0,
  autoEnrollment = 1,
  contractProduct = 2,
  acceptCashbackConditions = 3,
}

export enum RoleStandarizationSteps {
  SIGNATURE = 1,
  CONFIRMATION = 2,
}
