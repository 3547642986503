import {
  ChargePayrollMonthOptions,
  OrderValidationData,
  OrderValidationModel,
  OrderValidationRepositoryContract,
  OrderValidationResumeModel,
  OrderValidationCompleteModel,
} from 'src/Flex/Orders/domain'
import {
  calculateValidationOrder,
  getValidationOrder,
  validateValidationOrder,
} from 'src/config/constants/endpoints'
import { createQueryString, currency } from 'src/core/helpers'
import { edenredProducts } from 'src/domain/enum'
import { CardOrderResumeModel, HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { PaymentModes } from 'src/domain/enum'

type ApiOrderValidationData = {
  initialChargeDate: string
  orderReference: string | undefined
  doOrderNextMonth: boolean
  acceptConditions: boolean
  validations: {
    id: string
    monthAmount: number
  }[]
}

type ApiOrderValidation = HttpModel<{
  cutoffDate: string
  isOrderDoneThisMonth: boolean
  doOrderNextMonth: boolean | null
  canOrderBeUpdate: boolean
  pendingValidationMonth: string
  pendingValidations: {
    rows: {
      id: string
      name: string
      userName: string
      userLastName: string
      document: string
      productType: edenredProducts
      maxPerMonth: number
      monthAmount: number
      site: string
      beneficiary: string
      costCenter: string
      employeeNumber: string
    }[]
    totalRows: number
  }
  updateDate: string
} | null>

type ApiOrderResume = HttpModel<Partial<CardOrderResumeModel> | null>

type ApiOrderComplete = HttpModel<{
  cardOrderId: number | null
  chargeOrderId: number | null
  concept: string
  description: string | null
  email: string
  expireOrderDate: string
  iban: string | null
  initialChargeDate: string
  paymentMode: PaymentModes
  paymentModeName: string
  totalAmount: number
  ordersNeedPrepayment: boolean
  additionalData?: string
} | null>

const paymentModeToEnum = (paymentMode: string): PaymentModes | undefined => {
  switch (paymentMode) {
    case PaymentModes.wireTransfer:
      return PaymentModes.wireTransfer
    case PaymentModes.directDebit:
      return PaymentModes.directDebit
    default:
      return undefined
  }
}

const convertToDomain = (response: ApiOrderValidation): OrderValidationModel => ({
  orderLines:
    response.data?.pendingValidations.rows.map((pendingValidation, index) => ({
      id: pendingValidation.id,
      position: index,
      product: pendingValidation.productType,
      employee: {
        name: `${pendingValidation.userName} ${pendingValidation.userLastName}`,
        document: pendingValidation.document,
        number: pendingValidation.employeeNumber,
      },
      beneficiary: {
        name: pendingValidation.beneficiary,
      },
      maxAmount: pendingValidation.maxPerMonth,
      defaultAmount: pendingValidation.monthAmount,
      deliverySite: pendingValidation.site,
      costCenter: pendingValidation.costCenter,
    })) ?? [],
  cutoffDate: response.data?.cutoffDate ? new Date(response.data.cutoffDate) : undefined,
  chargePayrollMonth:
    response.data?.doOrderNextMonth === true
      ? ChargePayrollMonthOptions.Next
      : response.data?.doOrderNextMonth === false
      ? ChargePayrollMonthOptions.Current
      : undefined,
  month: response.data?.pendingValidationMonth
    ? new Date(response.data.pendingValidationMonth)
    : undefined,
  finished: response.data?.isOrderDoneThisMonth ?? false,
  updateDate: response.data?.updateDate,
  canOrderBeUpdate: response.data?.canOrderBeUpdate,
})

const convertToApi = (data: OrderValidationData): ApiOrderValidationData => ({
  initialChargeDate: new Date(data.chargeDate).toISOString(),
  orderReference: data.orderReference,
  doOrderNextMonth: data.chargePayrollMonth === ChargePayrollMonthOptions.Next,
  acceptConditions: data.terms,
  validations: data.orderLines
    .filter(orderLine => orderLine.active)
    .map(orderLine => ({
      id: orderLine.id,
      monthAmount: orderLine.amount,
    })),
})

const applyCurrency = (response: ApiOrderResume): OrderValidationResumeModel => ({
  detail: {
    paymentMode: paymentModeToEnum(response.data?.detail?.paymentMode ?? ''),
  },
  card: {
    services:
      response.data?.card?.services.map(service => ({
        ...service,
        value: currency(Number(service.value)),
      })) ?? [],
    quantity: response.data?.card?.quantity ?? 0,
    iva: currency(Number(response.data?.card?.iva)),
    subtotal: currency(Number(response.data?.card?.subtotal)),
    total: currency(Number(response.data?.card?.total)),
  },
  charge: {
    services:
      response.data?.charge?.services.map(service => ({
        ...service,
        value: currency(Number(service.value)),
      })) ?? [],
    quantity: response.data?.charge?.quantity ?? 0,
    iva: currency(Number(response.data?.charge?.iva)),
    subtotal: currency(Number(response.data?.charge?.subtotal)),
    total: currency(Number(response.data?.charge?.total)),
  },
  totalAmount: currency(Number(response.data?.totalAmount)),
  additionalData: response.data?.additionalData ?? '',
})

const convertSuccessToDomain = (
  response: ApiOrderComplete
): OrderValidationCompleteModel => ({
  email: response.data?.email ?? '',
  deliveryDate: response.data?.initialChargeDate ?? '',
  totalAmount: currency(Number(response.data?.totalAmount)),
  cardOrderId: response.data?.cardOrderId?.toString() ?? '',
  chargeOrderId: response.data?.chargeOrderId?.toString() ?? '',
  description: response.data?.description ?? '',
  iban: response.data?.iban ?? '',
  concept: response.data?.concept ?? '',
  paymentMode: response.data?.paymentMode,
  ordersNeedPrepayment: response.data?.ordersNeedPrepayment ?? false,
  additionalData: response.data?.additionalData ?? '',
})

export class OrderValidationRepository implements OrderValidationRepositoryContract {
  async GetOrderToValidate(
    productType: edenredProducts,
    afterCutoffDate?: boolean
  ): Promise<HttpModel<OrderValidationModel>> {
    const response: ApiOrderValidation = await axiosFetch(
      getValidationOrder +
        createQueryString({
          productType,
          afterCutoffDate,
        }),
      HttpMethod.GET,
      {}
    )

    return {
      data: convertToDomain(response),
      meta: response.meta,
    }
  }

  async CalculateValidationOrder(
    productType: edenredProducts,
    data: OrderValidationData
  ): Promise<HttpModel<OrderValidationResumeModel>> {
    const response: ApiOrderResume = await axiosFetch(
      calculateValidationOrder +
        createQueryString({
          productType,
        }),
      HttpMethod.POST,
      convertToApi(data)
    )

    return {
      data: applyCurrency(response),
      meta: response.meta,
    }
  }

  async ConfirmOrder(
    productType: edenredProducts,
    data: OrderValidationData
  ): Promise<HttpModel<OrderValidationCompleteModel>> {
    const response: ApiOrderComplete = await axiosFetch(
      validateValidationOrder +
        createQueryString({
          productType,
        }),
      HttpMethod.POST,
      convertToApi(data)
    )

    return {
      data: convertSuccessToDomain(response),
      meta: response.meta,
    }
  }
}
