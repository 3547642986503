import React from 'react'
import { LogaltyIframe } from '../../Logalty/ui/organisms'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'

export const RoleStandarizationStep1 = () => {
  const {
    logalty: { logalty },
    nextStep,
  } = useRoleStandarization()

  return (
    <div>
      <LogaltyIframe logalty={logalty} callback={nextStep} />
    </div>
  )
}
