export enum Repositories {
  account = 'account',
  autoenrollment = 'autoenrollment',
  user = 'user',
  deliverySites = 'deliverySites',
  spendingRules = 'spendingRules',
  cardOrder = 'cardOrder',
  excel = 'excel',
  invoice = 'invoice',
  employees = 'employees',
  generalTerms = 'generalTerms',
  kindergarten = 'kindergarten',
  flexOrderAdherence = 'flexOrderAdherence',
  flexProduct = 'flexProduct',
  flexEmployee = 'flexEmployee',
  flexEmployeeExcel = 'flexEmployeeExcel',
  flexCollective = 'flexCollective',
  flexDocument = 'flexDocument',
  flexOrdersImputations = 'flexOrdersImputations',
  flexOrderValidation = 'flexOrderValidation',
  history = 'history',
  flexProductImputation = 'flexProductImputation',
  flexCommunicationsTemplates = 'flexCommunicationsTemplates',
  flexPayrolls = 'flexPayrolls',
  flexTasks = 'flexTasks',
  flexReporting = 'flexReporting',
  tgdOrder = 'tgdOrder',
  tgdBasicOrder = 'tgdBasicOrder',
  ttOrder = 'ttOrder',
}
