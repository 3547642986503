import { TGDOrderSummaryButtons } from './TGDOrderSummaryButtons'
import { TGDOrderSummaryForm } from './TGDOrderSummaryForm'
import { Modal } from 'src/presentation/components/Edenred'
import { OreHeading, OreText } from '@runroom/oreneta'
import { TGDOrderSaveAndClose } from '../../TGDSaveAndCloseModal/organism/TGDOrderSaveAndClose'
import { TGDOrderSummaryState } from '../hooks/useTGDOrderSummaryContainer'

export const TGDOrderSummaryPartialContainer = (props: {
  partialProps: TGDOrderSummaryState
  data: any
}): JSX.Element => {
  return (
    <>
      <TGDOrderSummaryForm
        tgdProps={props.partialProps}
        isExcel={props.data && props.data.isExcel}
      />
      <TGDOrderSummaryButtons
        tgdProps={props.partialProps}
        isExcel={props.data && props.data.isExcel}
      />
      <Modal
        handleOnClose={props.partialProps.setShowDeleteModal}
        open={props.partialProps.showDeleteModal}
        buttons={props.partialProps.deletePopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {props.partialProps.deleteModalTitle}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {props.partialProps.deleteModalDescription}
          </OreText>
        </>
      </Modal>
      <Modal
        handleOnClose={props.partialProps.setShowCancelModal}
        open={props.partialProps.showCancelModal}
        buttons={props.partialProps.cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {props.partialProps.cancelModalTitle}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {props.partialProps.cancelModalDescription}
          </OreText>
        </>
      </Modal>
      <TGDOrderSaveAndClose
        setShowSaveCloseModal={props.partialProps.setShowSaveCloseModal}
        showSaveCloseModal={props.partialProps.showSaveCloseModal}
      />
    </>
  )
}
