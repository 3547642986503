import { useState, useEffect, KeyboardEvent, ChangeEvent } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreHeading,
  OreInput,
  OreStack,
  OreText,
  SvgFilter,
} from '@runroom/oreneta'
import {
  FiltersContainer,
  tableSearcherTranslations,
} from 'src/Flex/Shared/ui/TableSearcher'
import { FilterModel, FilterResultModel } from 'src/Flex/Shared/domain'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { EmployeePRFEnum } from 'src/Flex/Employees/domain/EmployeePRFEnum'

interface TableSearcherProps {
  handleSearch: (search: string, filter?: FilterResultModel[]) => void
  filters?: FilterModel[]
  searchPlaceholder?: string
  title?: string
  subtitle?: string
}

export const TableSearcher = ({
  handleSearch,
  filters = [],
  searchPlaceholder,
  title,
  subtitle,
}: TableSearcherProps) => {
  const { t } = useTranslation()
  const location = useLocation()

  const [search, setSearch] = useState<string>('')
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const [filtersValues, setFiltersValues] = useState<
    Array<FilterResultModel | undefined>
  >([])

  const isFilterResultModel = (
    item: FilterResultModel | undefined
  ): item is FilterResultModel => {
    return !!item
  }

  const doSearch = (
    search: string,
    filters: Array<FilterResultModel | undefined>
  ): void => {
    handleSearch(search, filters.filter(isFilterResultModel))
  }

  const handleEnterKey = (event: KeyboardEvent): void => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()

      doSearch(search, filtersValues)
    }
  }

  const searchButton = (): JSX.Element => (
    <OreButton
      category="primary"
      size="small"
      onClick={() => doSearch(search, filtersValues)}>
      {t(tableSearcherTranslations.search)}
    </OreButton>
  )

  const onSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value)

    if (event.target.value === '') {
      doSearch('', filtersValues)
    }
  }

  const employeeListFilter = (filterParams: FilterResultModel[]): FilterResultModel[] => {
    const filters: FilterResultModel[] = filterParams.filter(
      f => f.name !== 'flexRetributionPlanRegisterStep' && f.name !== 'isWelcomeEmailSent'
    )
    const contractFilters = filterParams.filter(
      f => f.name == 'flexRetributionPlanRegisterStep' || f.name == 'isWelcomeEmailSent'
    )
    if (
      contractFilters[0].name === 'flexRetributionPlanRegisterStep' &&
      contractFilters[0].value === EmployeePRFEnum.pending.toString()
    ) {
      filters.push({
        name: 'flexRetributionPlanRegisterStep',
        value: EmployeePRFEnum.pending.toString(),
      })
    }
    ///flexRetributionPlanRegisterStep
    if (
      contractFilters[0].name === 'flexRetributionPlanRegisterStep' &&
      contractFilters[0].value === EmployeePRFEnum.notStarted.toString()
    ) {
      filters.push({
        name: 'flexRetributionPlanRegisterStep',
        value: EmployeePRFEnum.notStarted.toString(),
      })
    }
    if (
      contractFilters[0].name === 'flexRetributionPlanRegisterStep' &&
      contractFilters[0].value === EmployeePRFEnum.done.toString()
    ) {
      filters.push({
        name: 'flexRetributionPlanRegisterStep',
        value: EmployeePRFEnum.done.toString(),
      })
    }
    if (
      contractFilters[0].name === 'flexRetributionPlanRegisterStep' &&
      contractFilters[0].value === EmployeePRFEnum.cancelled.toString()
    ) {
      filters.push({
        name: 'flexRetributionPlanRegisterStep',
        value: EmployeePRFEnum.cancelled.toString(),
      })
    }
    if (
      contractFilters[0].name === 'isWelcomeEmailSent' &&
      contractFilters[0].value === 'false'
    ) {
      filters.push({
        name: 'isWelcomeEmailSent',
        value: 'false',
      })
    }
    return filters
  }

  useEffect(() => {
    setSearch('')
    let filterParams =
      location.search !== ''
        ? location.search
            .replace('?', '')
            .split('&')
            .map(param => {
              const [key, value] = param.split('=')
              return { name: key, value }
            })
        : undefined
    filterParams = filterParams?.filter(param => param.name !== 'adherenceTab')
    if (filters.length > 0 && filterParams !== undefined) {
      if (location.pathname === flexNavigationRoutes.flexEmployees) {
        filterParams = employeeListFilter(filterParams)
      }
      setFiltersValues(
        filters.map(filter => ({
          name: filter.id,
          value: filterParams?.find(param => param.name === filter.id)?.value || '',
        }))
      )
    }

    if (filterParams !== undefined && filterParams.length > 0) {
      setFiltersOpen(true)
      doSearch('', filterParams)
    }
  }, [location])

  return (
    <OreCardContainer radius="top">
      <OreCardWrapper space="large">
        <OreStack placeContent="stretch">
          <OreStack direction="row" placeItems="center">
            {title ? <OreHeading size="headline-md">{title}</OreHeading> : null}
            {subtitle && <OreText size="text-sm">{subtitle}</OreText>}
          </OreStack>
          <OreStack direction="row" placeContent="space-between" placeItems="center">
            <OreStack direction="row">
              <OreInput
                style={{ minWidth: '360px' }}
                type="search"
                name="search"
                id="search"
                onKeyDown={handleEnterKey}
                placeholder={
                  searchPlaceholder || t(tableSearcherTranslations.searchPlaceholder)
                }
                onChange={event => onSearch(event)}
                value={search}
              />
              {filtersOpen ? null : searchButton()}
            </OreStack>

            {filters.length > 0 ? (
              <OreButton
                as="a"
                icon={<SvgFilter />}
                onClick={() => setFiltersOpen(!filtersOpen)}
                category="tertiary">
                {t(tableSearcherTranslations.filters)}
              </OreButton>
            ) : null}
          </OreStack>
          {filtersOpen ? (
            <OreStack placeContent="stretch">
              <FiltersContainer
                filters={filters}
                setFilters={setFiltersValues}
                currentFilters={filtersValues}
              />
              <OreStack direction="row">
                {searchButton()}
                <OreButton
                  category="secondary"
                  size="small"
                  onClick={() => {
                    setFiltersValues([])
                    setSearch('')
                    doSearch('', [])
                  }}>
                  {t(tableSearcherTranslations.clear)}
                </OreButton>
              </OreStack>
            </OreStack>
          ) : null}
        </OreStack>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
