import {
  OreCardActions,
  OreCardContainer,
  OreCardWrapper,
  OreStack,
  OreText,
} from '@runroom/oreneta'

interface LogaltyPendingProps {
  description: string
}

export const LogaltyPending = ({ description }: LogaltyPendingProps): JSX.Element => (
  <OreCardContainer>
    <OreCardWrapper>
      <OreStack placeContent="stretch" space="large">
        <OreStack placeContent="stretch" space="large">
          <OreText>{description}</OreText>
        </OreStack>
      </OreStack>
    </OreCardWrapper>
    <OreCardActions></OreCardActions>
  </OreCardContainer>
)
