import { useEffect } from 'react'
import { LogaltyResponseStatus } from '../../domain'
import { IUseLogaltyFirmController } from '../hooks'

interface LogaltyStatusCheckerProps {
  children: JSX.Element
  logalty: IUseLogaltyFirmController
  getSignStatus: () => Promise<void>
  currentModel: unknown
}

export const LogaltyStatusChecker = ({
  children,
  logalty,
  getSignStatus,
  currentModel,
}: LogaltyStatusCheckerProps): JSX.Element | null => {
  const { MAX_STATUS_ATTEMPTS, signStatus, setError } = logalty

  useEffect(() => {
    let isSubscribed = true
    let currentAttempts = 0
    let isRequesting = false

    const fetchSignStatus = (): void => {
      if (currentAttempts >= MAX_STATUS_ATTEMPTS) {
        setError()
        return
      }

      if (
        isSubscribed &&
        currentModel !== null &&
        signStatus === LogaltyResponseStatus.Pending &&
        !isRequesting
      ) {
        isRequesting = true

        getSignStatus().finally(() => {
          isRequesting = false
          currentAttempts++
        })
      }
    }

    fetchSignStatus()
    const checkStatusInterval = setInterval(fetchSignStatus, 8000)

    return () => {
      clearInterval(checkStatusInterval)
      isSubscribed = false
    }
  }, [signStatus])

  return children
}
