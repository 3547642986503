import { useState } from 'react'
import { LogaltyModel, LogaltyResponseStatus } from '../../domain'
import { HttpModel } from 'src/domain/models'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'

export interface IUseLogaltyFirmController {
  logalty: LogaltyModel
  setLogalty: (logalty: LogaltyModel) => void
  signStatus: LogaltyResponseStatus
  setSignStatus: (status: LogaltyResponseStatus) => void
  getSignStatus: (...args: unknown[]) => Promise<void>
  setError: () => void
  MAX_STATUS_ATTEMPTS: number
}

export const useLogaltyFirmController = (
  getSignStatusFunction: (...args: unknown[]) => Promise<HttpModel<LogaltyResponseStatus>>
): IUseLogaltyFirmController => {
  // const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()

  const MAX_STATUS_ATTEMPTS = 5

  const [logalty, setLogalty] = useState<LogaltyModel>({
    signUrl: '',
    signSAML: '',
  })

  const [signStatus, setSignStatus] = useState<LogaltyResponseStatus>(
    LogaltyResponseStatus.Pending
  )

  const getSignStatus = async (...args: unknown[]): Promise<void> => {
    // startLoading()

    const response = await getSignStatusFunction(...args)

    // stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setSignStatus(response?.data || LogaltyResponseStatus.Pending)
    }
  }

  const setError = (): void => {
    setSignStatus(LogaltyResponseStatus.Error)
  }

  return {
    logalty,
    setLogalty,
    signStatus,
    setSignStatus,
    getSignStatus,
    setError,
    MAX_STATUS_ATTEMPTS,
  }
}
