import { yupResolver } from '@hookform/resolvers/yup'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormReturn } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { accountService } from '../../../../../core/services'
import { userService } from '../../../../../core/services/userService'
import { getAccountsMap } from '../../../../../domain/adapters/accountAdapter'
import type { HttpModel, MessagesModel, UserModel } from '../../../../../domain/models'
import { forms } from '../../../../../domain/translations'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useNotification } from '../../../../context/notification/NotificationProvider'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'

interface UserDataState {
  user: UserModel | undefined
  isEditing: boolean
  setIsEditing: Dispatch<SetStateAction<boolean>>
  editEmail: () => void
  form: UseFormReturn<UserModel, any>
  errorMessage: string
}

export const useUserDataController = (): UserDataState => {
  const [user, setUser] = useState<UserModel>()
  const { startLoading, stopLoading } = useLoader()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const [errorMessage, setStateErrorMessage] = useState<string>('')

  const formSchema = Yup.object({
    email: Yup.string()
      .required(t(forms.errors.fieldRequired))
      .email(t(forms.errors.invalidFormat)),
  })

  const form = useForm<UserModel>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: true,
  })

  const GetUserData = async (): Promise<HttpModel<UserModel>> => {
    return new Promise<HttpModel<UserModel>>(resolve => {
      startLoading()
      userService()
        .GetUserData()
        .then(response => {
          if (response.data) {
            const result = getAccountsMap(response.data)
            form.setValue('email', result.email)
            setUser(result)
          }
          resolve(response)
        })
        .finally(() => stopLoading())
    })
  }

  const editEmail = (): void => {
    setStateErrorMessage('')
    startLoading()
    accountService()
      .editEmail(form.getValues('email'))
      .then(response => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          addNotification(
            t(accountSettingsTranslation.myData.editSuccess),
            NotificationSeverity.success
          )
          setIsEditing(false)
          window.location.reload()
        } else {
          if (response?.meta?.messages.length > 0) {
            response?.meta?.messages.forEach((error: MessagesModel) => {
              if (error.value) {
                form.setError(error.value as any, {
                  type: 'custom',
                  message: error.description,
                })
              } else {
                setStateErrorMessage(response?.meta?.messages[0].description)
              }
            })
          }
        }
      })
      .catch(() =>
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      )
      .finally(() => stopLoading())
  }

  useEffect(() => {
    if (user) return
    GetUserData()
  }, [])

  return { user: user, isEditing, setIsEditing, form, editEmail, errorMessage }
}
