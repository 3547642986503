export const roleStandarizationTranslation = {
  modal: {
    title: 'roleStandarization.modal.title',
    description: {
      first: 'roleStandarization.modal.description.first',
      second: 'roleStandarization.modal.description.second',
      third: 'roleStandarization.modal.description.third',
      fourth: 'roleStandarization.modal.description.fourth',
    },
    buttons: {
      accept: 'roleStandarization.modal.buttons.accept',
      reject: 'roleStandarization.modal.buttons.reject',
    },
  },
  messageBar: {
    first: 'roleStandarization.messageBar.first',
    second: 'roleStandarization.messageBar.second',
    third: 'roleStandarization.messageBar.third',
    fourth: 'roleStandarization.messageBar.fourth',
    fifth: 'roleStandarization.messageBar.fifth',
  },
  acceptancePage: {
    title: 'roleStandarization.acceptancePage.title',
    description: 'roleStandarization.acceptancePage.description',
    stepper: {
      step1: 'roleStandarization.acceptancePage.stepper.step1',
      step2: 'roleStandarization.acceptancePage.stepper.step2',
    },
    pendingScreen: {
      description: 'roleStandarization.acceptancePage.pendingScreen.description',
    },
    successScreen: {
      title: 'roleStandarization.acceptancePage.successScreen.title',
      description: 'roleStandarization.acceptancePage.successScreen.description',
      buttonLabel: 'roleStandarization.acceptancePage.successScreen.buttonLabel',
    },
    errorScreen: {
      title: 'roleStandarization.acceptancePage.errorScreen.title',
      description: 'roleStandarization.acceptancePage.errorScreen.description',
      buttonLabel: 'roleStandarization.acceptancePage.errorScreen.buttonLabel',
    },
  },
}
