import { ComponentProps, ElementType, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { OreHeading, OreStack, OreStepper } from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import {
  OrderValidationProvider,
  OrderValidationStepOne,
  OrderValidationStepThree,
  OrderValidationStepTwo,
  ProductTypeSlugs,
  Steps,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { edenredProducts } from 'src/domain/enum'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { useUserData } from 'src/Flex/User/ui/context'
import { OrderValidationInactiveProduct } from './molecules/OrderValidationInactiveProduct'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

const getStep = (step: Steps | undefined): ComponentProps<ElementType> | undefined => {
  switch (step) {
    case Steps.StepOne:
      return OrderValidationStepOne
    case Steps.StepTwo:
      return OrderValidationStepTwo
    case Steps.StepThree:
      return OrderValidationStepThree
  }
}

const getProductType = (
  productType: ProductTypeSlugs | undefined
): edenredProducts | undefined => {
  switch (productType) {
    case ProductTypeSlugs.TicketRestaurant:
      return edenredProducts.ticketRestaurant
    case ProductTypeSlugs.Movilidad:
      return edenredProducts.ticketTransporte
    case ProductTypeSlugs.Guarderia:
      return edenredProducts.ticketGuarderia
  }
}

export const OrderValidationPage = (): JSX.Element => {
  const { step, productType: rawProductType } = useParams<{
    step: Steps
    productType: ProductTypeSlugs
  }>()
  const [stepError, setStepError] = useState<number>(0)

  const [productIsActive, setProductIsActive] = useState<boolean>(true)
  const { getAllProducts } = useUserData()

  const navigate = useNavigate()
  const { t } = useTranslation()

  const Step = getStep(step)
  const productType = getProductType(rawProductType)

  if (!Step || !productType) {
    navigate(
      flexNavigationRoutes.flexOrdersValidate
        .replace(':productType', 'TicketRestaurant')
        .replace(':step', Steps.StepOne)
    )

    return <></>
  }

  const stepNumber =
    Step === OrderValidationStepOne ? 1 : Step === OrderValidationStepTwo ? 2 : 3

  useEffect(() => {
    if (Step === OrderValidationStepOne) {
      setStepError(0)
    }
  }, [Step])

  const getProductIsActive = (productType: number): boolean => {
    const prod = getAllProducts.find(x => x.productType === productType)
    if (prod) {
      return !!prod.active
    }
    return false
  }

  useEffect(() => {
    setProductIsActive(getProductIsActive(productType))
  }, [productType])

  return (
    <OrderValidationProvider productType={productType}>
      <PageWrapper>
        <OreStack space="large" placeContent="stretch">
          <OreHeading as="h1" size="title-md">
            {productIsActive
              ? t(orderValidationsTranslations.title, {
                  product: t(flexProductsTranslations.ids[productType]),
                })
              : t(orderValidationsTranslations.configure.title, {
                  product: t(flexProductsTranslations.ids[productType]),
                })}
          </OreHeading>
          {productIsActive ? (
            <>
              <OreStepper
                activeStep={stepNumber}
                errorStep={stepError}
                steps={[
                  t(orderValidationsTranslations.step1.title),
                  t(orderValidationsTranslations.step2.title),
                  t(orderValidationsTranslations.step3.title),
                ]}
              />
              <Step setStepError={setStepError} />
              {Step === OrderValidationStepOne && (
                <VideoTutorial
                  title={t(orderValidationsTranslations.step1.videoTutorial.text)}
                  youtubeUrl="https://www.youtube.com/embed/fv32gk1NQtA?si=nMzEi4Udw734b5r4"
                />
              )}
            </>
          ) : (
            <OrderValidationInactiveProduct productType={productType} />
          )}
        </OreStack>
      </PageWrapper>
    </OrderValidationProvider>
  )
}
