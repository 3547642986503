import { OreHeading, OreMessageBar, OreText } from '@runroom/oreneta'
import { Modal } from 'src/presentation/components/Edenred'
import { useTGDOrderSummaryContainer } from '../hooks/useTGDOrderSummaryContainer'
import { TGDOrderSummaryTable } from '../molecules/TGDOrderSummaryTable'
import { TGDOrderSummaryForm, TGDOrderSummaryPartialContainer } from '../molecules'
import { TGDOrderSummaryButtons } from '../molecules/TGDOrderSummaryButtons'
import { TGDOrderSaveAndClose } from '../../TGDSaveAndCloseModal/organism/TGDOrderSaveAndClose'
import { useLocation } from 'react-router-dom'
import { TGDOrderSummaryExcelTable } from '../molecules/TGDOrderSummaryExcelTable'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'
import { ModalSpinner } from 'src/Flex/Shared'
import {
  OrderValidationErrorStep,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'

export const TGDOrderSummaryContainer = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()
  const data: any = location.state
  const props = useTGDOrderSummaryContainer(data ? data.isExcel : false)
  const { showSpinner, errorData, excelError, backToSummary } = props

  return (
    <>
      {props.existChildsOlderThan3 && (
        <OreMessageBar color="warning">
          {t(TGDOrderTranslation.summaryAssignment.messageBar.childsOlderThan3Warning)}
        </OreMessageBar>
      )}
      {data && data.isExcel ? (
        <>
          <TGDOrderSummaryExcelTable />
          <TGDOrderSummaryPartialContainer data={data} partialProps={props} />
        </>
      ) : !errorData ? (
        <>
          <TGDOrderSummaryTable tgdProps={props} />
          <TGDOrderSummaryPartialContainer data={data} partialProps={props} />
          <ModalSpinner
            description={t(orderValidationsTranslations.spinner.description)}
            title={t(orderValidationsTranslations.spinner.title)}
            show={showSpinner}
          />
        </>
      ) : (
        <OrderValidationErrorStep
          errors={errorData}
          excelError={excelError}
          back={backToSummary}
        />
      )}
    </>
  )
}
