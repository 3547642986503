import React from 'react'
import { useTTOrderSummaryController } from '../hooks/useTTOrderSummaryController'
import {
  TTOrderSummaryButtons,
  TTOrderSummaryForm,
  TTOrderSummaryTable,
} from '../molecules'
import { Modal } from 'src/presentation/components/Edenred'
import { OreHeading, OreText } from '@runroom/oreneta'
import { TTOrderSaveAndClose } from '../../TTOrderSaveAndClose/TTOrderSaveAndClose'
import {
  OrderValidationErrorStep,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'
import { ModalSpinner } from 'src/Flex/Shared'
import { useTranslation } from 'react-i18next'

export const TTOrderSummaryContainer = (): JSX.Element => {
  const { t } = useTranslation()
  const props = useTTOrderSummaryController()
  const {
    showDeleteModal,
    setShowDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    errorData,
    excelError,
    backToSummary,
    showSpinner,
  } = props
  return (
    <>
      {!errorData ? (
        <>
          <TTOrderSummaryTable ttProps={props} />
          <TTOrderSummaryForm ttProps={props} />
          <TTOrderSummaryButtons ttProps={props} />
          <ModalSpinner
            description={t(orderValidationsTranslations.spinner.description)}
            title={t(orderValidationsTranslations.spinner.title)}
            show={showSpinner}
          />
          <Modal
            handleOnClose={setShowDeleteModal}
            open={showDeleteModal}
            buttons={deletePopupButtons}
            closeIcon={false}>
            <>
              <OreHeading size="headline-lg">{deleteModalTitle}</OreHeading>
              <OreText align="left" as="p" size="text-md" tone="neutral">
                {deleteModalDescription}
              </OreText>
            </>
          </Modal>
          <Modal
            handleOnClose={setShowCancelModal}
            open={showCancelModal}
            buttons={cancelPopupButtons}
            closeIcon={false}>
            <>
              <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
              <OreText align="left" as="p" size="text-md" tone="neutral">
                {cancelModalDescription}
              </OreText>
            </>
          </Modal>
          <TTOrderSaveAndClose
            setShowSaveCloseModal={props.setShowSaveCloseModal}
            showSaveCloseModal={props.showSaveCloseModal}
          />
        </>
      ) : (
        <OrderValidationErrorStep
          errors={errorData}
          excelError={excelError}
          back={backToSummary}
        />
      )}
    </>
  )
}
