import { OreNavBarHeader } from '@runroom/oreneta'
import { useUI } from '../../../../context/ui/UIProvider'
import { DevLogin, NavbarUser } from './molecules'
import { Help } from './molecules/Help'
import MultiCIF from './molecules/MultiCIF'

const Header = ({ noSidebar }: { noSidebar?: boolean }): JSX.Element => {
  const { sidebarCollapsed, toggleSidebar } = useUI()

  function HeaderComponent({ children }: { children: React.ReactNode }) {
    if (noSidebar) {
      return <OreNavBarHeader>{children}</OreNavBarHeader>
    }
    return (
      <OreNavBarHeader handleClick={toggleSidebar} activeBurger={sidebarCollapsed}>
        {children}
      </OreNavBarHeader>
    )
  }

  return (
    <HeaderComponent>
      <MultiCIF />
      <DevLogin />
      <Help />
      <NavbarUser />
    </HeaderComponent>
  )
}

export default Header
