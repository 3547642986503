import { ConvertToISODateOnly } from 'src/core/helpers'
import { cardRequestTypeId, edenredProducts, orderType } from '../enum'
import {
  TGDAssignmentRequestBody,
  TGDChildModel,
  TGDEmployeeAndChildsModel,
  TGDOrderRequestBody,
  TGDOrderSaveAndCloseRequestModel,
  TGDOrderSummaryForm,
  TGDOrderSummaryRequestModel,
} from '../models/TGDOrder/TGDOrderModel'
import { MessagesModel } from '../models'

export const SetTGDRequestBody = (
  order: TGDEmployeeAndChildsModel
): TGDOrderRequestBody => {
  const arrayTGDOrder: TGDAssignmentRequestBody[] = []
  order.childs.forEach((child: TGDChildModel) => {
    arrayTGDOrder.push({
      employeeData: {
        userId: order.userId,
        companyId: order.companyId,
        name: order.name,
        firstSurname: order.firstSurname,
        secondSurname: order.secondSurname,
        telephone: order.telephone,
        birthDate: '2000-01-01',
        email: order.email,
        employeeNumber: order.employeeNumber,
        documentTypeId: order.documentTypeId,
        document: order.document,
        costCenter: order.costCenter,
      },
      employeeSon: {
        ...child,
        birthDate: child.birthDate ? child.birthDate : '',
      },
      cardData: { initialAmount: child.initialAmount ? child.initialAmount : 0 },
    })
  })
  return {
    productCode: edenredProducts.ticketGuarderia,
    cardOrders: arrayTGDOrder,
  }
}

export const SetTGDRequestCheckoutBody = (
  summary: TGDOrderSummaryForm,
  tgdOrders: TGDEmployeeAndChildsModel[]
): TGDOrderSummaryRequestModel => {
  const arrayTGDOrder: TGDAssignmentRequestBody[] = []
  tgdOrders.forEach(employeeAndChild => {
    employeeAndChild.childs.forEach((child: TGDChildModel) => {
      arrayTGDOrder.push({
        employeeData: {
          userId: employeeAndChild.userId,
          companyId: employeeAndChild.companyId,
          name: employeeAndChild.name,
          firstSurname: employeeAndChild.firstSurname,
          secondSurname: employeeAndChild.secondSurname,
          telephone: employeeAndChild.telephone,
          birthDate: '2000-01-01',
          email: employeeAndChild.email,
          employeeNumber: employeeAndChild.employeeNumber,
          documentTypeId: employeeAndChild.documentTypeId,
          document: employeeAndChild.document,
          costCenter: child.costCenter ? child.costCenter : employeeAndChild.costCenter,
        },
        employeeSon: {
          ...child,
          birthDate: child.birthDate ? child.birthDate : '',
        },
        cardData: { initialAmount: child.initialAmount ? child.initialAmount : 0 },
      })
    })
  })
  return {
    acceptConditions: summary.acceptConditions,
    billReference: summary.billReference,
    initialChargeDate: ConvertToISODateOnly(summary.initialChargeDate),
    productCode: edenredProducts.ticketGuarderia,
    cardOrders: arrayTGDOrder,
    orderTypeId: summary.orderTypeId,
  }
}

export const SetTGDSaveAndCloseBody = (
  orderName: string,
  tgdOrders: TGDEmployeeAndChildsModel[],
  preOrderId?: number
): TGDOrderSaveAndCloseRequestModel => {
  const arrayTGDOrder: TGDAssignmentRequestBody[] = []
  tgdOrders.forEach(employeeAndChild => {
    employeeAndChild.childs.forEach((child: TGDChildModel) => {
      arrayTGDOrder.push({
        employeeData: {
          userId: employeeAndChild.userId,
          companyId: employeeAndChild.companyId,
          name: employeeAndChild.name,
          firstSurname: employeeAndChild.firstSurname,
          secondSurname: employeeAndChild.secondSurname,
          telephone: employeeAndChild.telephone,
          birthDate: '2000-01-01',
          email: employeeAndChild.email,
          employeeNumber: employeeAndChild.employeeNumber,
          documentTypeId: employeeAndChild.documentTypeId,
          document: employeeAndChild.document,
          costCenter: employeeAndChild.costCenter,
        },
        employeeSon: {
          ...child,
          birthDate: child.birthDate ? child.birthDate : '',
          kindergartenName: child.kindergartenData?.name,
          kindergartenStreetType: child.kindergartenData?.streetType,
          kindergartenStreetName: child.kindergartenData?.streetName,
          kindergartenStreetNumber: child.kindergartenData?.streetNumber,
          kindergartenStreetDescription1: child.kindergartenData?.streetDescription1,
          kindergartenStreetDescription2: child.kindergartenData?.streetDescription2,
          kindergartenZipCode: child.kindergartenData?.zipCode,
          kindergartenCity: child.kindergartenData?.city,
          kindergartenProvince: child.kindergartenData?.province,
          kindergartenTelephone: child.kindergartenData?.telephone,
          kindergartenEmail: child.kindergartenData?.email,
          kindergartenLatitude: child.kindergartenData?.latitude,
          kindergartenLongitude: child.kindergartenData?.longitude,
        },
        cardData: {
          initialAmount: child.initialAmount ? child.initialAmount : 0,
          cardRequestTypeId: employeeAndChild.tgdOrderType,
        },
      })
    })
  })
  return {
    orderName: orderName,
    preOrderId: preOrderId,
    orderTypeId: orderType.NurseryAssignmentOrder,
    productCode: edenredProducts.ticketGuarderia,
    cardOrders: arrayTGDOrder,
  }
}

export const GetTGDSaveAndCloseBody = (
  orderName: string,
  tgdOrders: TGDEmployeeAndChildsModel[]
): TGDOrderSaveAndCloseRequestModel => {
  const arrayTGDOrder: TGDAssignmentRequestBody[] = []
  tgdOrders.forEach(employeeAndChild => {
    employeeAndChild.childs.forEach((child: TGDChildModel) => {
      arrayTGDOrder.push({
        employeeData: {
          userId: employeeAndChild.userId,
          companyId: employeeAndChild.companyId,
          name: employeeAndChild.name,
          firstSurname: employeeAndChild.firstSurname,
          secondSurname: employeeAndChild.secondSurname,
          telephone: employeeAndChild.telephone,
          birthDate: '2000-01-01',
          email: employeeAndChild.email,
          employeeNumber: employeeAndChild.employeeNumber,
          documentTypeId: employeeAndChild.documentTypeId,
          document: employeeAndChild.document,
          costCenter: employeeAndChild.costCenter,
        },
        employeeSon: {
          ...child,
          birthDate: child.birthDate ? child.birthDate : '',
        },
        cardData: {
          initialAmount: child.initialAmount ? child.initialAmount : 0,
          cardRequestTypeId: cardRequestTypeId.credit,
        },
      })
    })
  })
  return {
    orderName: orderName,
    orderTypeId: orderType.NurseryAssignmentOrder,
    productCode: edenredProducts.ticketGuarderia,
    cardOrders: arrayTGDOrder,
  }
}

// return field name from cardOrderModel to TGDEmployeeAndChildsModel
export const TGDFormErrorFieldAdapter = (errorMessage: MessagesModel): string => {
  const childIndex = errorMessage.value.split('.')[0].match(/\d+/)

  // is child field
  if (errorMessage.value.includes('employeeSon')) {
    return `childs.${childIndex}.` + errorMessage.value.split('.').slice(2).join('.')
  }

  // is employee field
  return errorMessage.value.split('.').slice(2).join('.')
}
