export const TGDBasicOrderTranslation = {
  title: 'tgdBasicOrderTranslation.title',

  form: {
    title: 'tgdBasicOrderTranslation.form.title',
    titleEdit: 'tgdBasicOrderTranslation.form.titleEdit',
    childTitle: 'tgdBasicOrderTranslation.form.childTitle',
    childTitleEdit: 'tgdBasicOrderTranslation.form.childTitleEdit',
    mandatoryFields: 'tgdBasicOrderTranslation.form.mandatoryFields',
    addToOrder: 'tgdBasicOrderTranslation.form.addToOrder',
    checkoutButton: 'tgdBasicOrderTranslation.form.checkoutButton',

    user: {
      title: 'tgdBasicOrderTranslation.form.user.title',
      childTitle: 'tgdBasicOrderTranslation.form.user.childTitle',
      documentType: {
        helpText: 'tgdBasicOrderTranslation.form.user.documentType.helpText',
      },
      corporativeEmail: {
        helpText: 'tgdBasicOrderTranslation.form.user.corporativeEmail.helpText',
      },
      errors: {
        existsInServer: 'tgdBasicOrderTranslation.form.user.errors.existsInServer',
        existsInOrder: 'tgdBasicOrderTranslation.form.user.errors.existsInOrder',
      },
    },
    child: {
      title: 'tgdBasicOrderTranslation.form.child.title',
      addAnotherChild: 'tgdBasicOrderTranslation.form.child.addAnotherChild',
      deleteChild: 'tgdBasicOrderTranslation.form.child.deleteChild',
      deleteModalTitle: 'tgdBasicOrderTranslation.form.child.deleteModalTitle',
      deleteModalDescription:
        'tgdBasicOrderTranslation.form.child.deleteModalDescription',
      cancelModalTitle: 'tgdBasicOrderTranslation.form.child.cancelModalTitle',
      cancelModalDescription:
        'tgdBasicOrderTranslation.form.child.cancelModalDescription',
      repeatedChildModalTitle: 'tgdOrderTranslation.form.child.repeatedChildModalTitle',
      repeatedChildModalRadio: {
        mustBeAnError:
          'tgdOrderTranslation.form.child.repeatedChildModalRadio.mustBeAnError',
        continue: 'tgdOrderTranslation.form.child.repeatedChildModalRadio.continue',
      },
      birthdate: {
        helpText: 'tgdBasicOrderTranslation.form.child.birthdate.helpText',
      },
      rechargeAmount: {
        label: 'tgdBasicOrderTranslation.form.child.rechargeAmount.label',
        helpText: 'tgdBasicOrderTranslation.form.child.rechargeAmount.helpText',
      },
      errors: {
        validateMinDateBirth:
          'tgdBasicOrderTranslation.form.child.errors.validateMinDateBirth',
        validateMaxDateBirth:
          'tgdBasicOrderTranslation.form.child.errors.validateMaxDateBirth',
        amountTwoDecimalsMax:
          'tgdBasicOrderTranslation.form.child.errors.amountTwoDecimalsMax',
        repeatedNameOrBirthDate:
          'tgdBasicOrderTranslation.form.child.errors.repeatedNameOrBirthDate',
        repeatedName: 'tgdBasicOrderTranslation.form.child.errors.repeatedName',
      },
      warnings: {
        specialEducation: 'tgdBasicOrderTranslation.form.child.warnings.specialEducation',
      },
    },
  },
  summary: {
    table: {
      title: 'tgdBasicOrderTranslation.summary.table.title',
      subtitle: 'tgdBasicOrderTranslation.summary.table.subtitle',
      header: {
        user: 'tgdBasicOrderTranslation.summary.table.header.user',
        child: 'tgdBasicOrderTranslation.summary.table.header.child',
        initialBalance: 'tgdBasicOrderTranslation.summary.table.header.initialBalance',
        amount: 'tgdBasicOrderTranslation.summary.table.header.amount',
        totalBalance: 'tgdBasicOrderTranslation.summary.table.header.totalBalance',
        actions: 'tgdBasicOrderTranslation.summary.table.header.actions',
      },
      footer: {
        addEmployeeChild:
          'tgdBasicOrderTranslation.summary.table.footer.addEmployeeChild',
        addChild: 'tgdBasicOrderTranslation.summary.table.footer.addChild',
        addAssignment: 'tgdBasicOrderTranslation.summary.table.footer.addAssignment',
      },
      emptyTable: {
        emptyText: 'tgdBasicOrderTranslation.summary.table.emptyTable.emptyText',
        createNewOrderTitle:
          'tgdBasicOrderTranslation.summary.table.emptyTable.createNewOrderTitle',
        createNewOrderDescription:
          'tgdBasicOrderTranslation.summary.table.emptyTable.createNewOrderDescription',
        createNewOrderOption1:
          'tgdBasicOrderTranslation.summary.table.emptyTable.createNewOrderOption1',
        createNewOrderOption2:
          'tgdBasicOrderTranslation.summary.table.emptyTable.createNewOrderOption2',
        createNewOrderOption3:
          'tgdBasicOrderTranslation.summary.table.emptyTable.createNewOrderOption3',
      },
    },
    completeOrder: {
      title: 'tgdBasicOrderTranslation.summary.completeOrder.title',
      dateDescription: 'tgdBasicOrderTranslation.summary.completeOrder.dateDescription',
      dateAssignment: 'tgdBasicOrderTranslation.summary.completeOrder.dateAssignment',
      dateAssignmentLabelTooltip:
        'tgdBasicOrderTranslation.summary.completeOrder.dateAssignmentLabelTooltip',
      dateAssignmentPlaceholder:
        'tgdBasicOrderTranslation.summary.completeOrder.dateAssignmentPlaceholder',
      billReferenceDescription:
        'tgdBasicOrderTranslation.summary.completeOrder.billReferenceDescription',
      billReference: 'tgdBasicOrderTranslation.summary.completeOrder.billReference',
      billReferencePlaceholder:
        'tgdBasicOrderTranslation.summary.completeOrder.billReferencePlaceholder',
      fieldsRequired: 'tgdBasicOrderTranslation.summary.completeOrder.fieldsRequired',
      termsAcceptance1: 'tgdBasicOrderTranslation.summary.completeOrder.termsAcceptance1',
      termsAcceptance2: 'tgdBasicOrderTranslation.summary.completeOrder.termsAcceptance2',
    },
    deleteModal: {
      title: 'tgdBasicOrderTranslation.summary.deleteModal.title',
      description: 'tgdBasicOrderTranslation.summary.deleteModal.description',
    },
    cancelModal: {
      title: 'tgdBasicOrderTranslation.summary.cancelModal.title',
      description: 'tgdBasicOrderTranslation.summary.cancelModal.description',
    },
    tooltips: {
      newEmployeeChild: 'tgdBasicOrderTranslation.summary.tooltips.newEmployeeChild',
      editNewChild: 'tgdBasicOrderTranslation.summary.tooltips.editNewChild',
      editAssignment: 'tgdBasicOrderTranslation.summary.tooltips.editAssignment',
    },
  },
}
