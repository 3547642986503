import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useModal } from 'src/presentation/hooks'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useModalController } from 'src/presentation/components/Edenred'
import { forms } from 'src/domain/translations'
import {
  NotificationSeverity,
  cardOrderTypeEnum,
  edenredProducts,
  orderType,
} from 'src/domain/enum'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderService } from 'src/core/services/ttOrderService'
import { TTOrderSummaryForm } from 'src/domain/models/TTOrder/TTOrderModel'
import { GetExcelOrderTypeRequest } from 'src/core/controllers/cardOrder/services'

export interface TTOrderCheckoutState {
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  showSaveCloseModal: boolean
  setShowSaveCloseModal: Dispatch<SetStateAction<boolean>>
  ttOrderCheckout: CardOrderResumeDTO
  ttSummaryForm: TTOrderSummaryForm
  confirmExcelOrder: () => Promise<void>
  confirmOrder: () => Promise<void>
}
export const useTTOrderCheckout = (): TTOrderCheckoutState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()

  const {
    ttOrders,
    clearTTOrders,
    setTTEmployeesToConfig,
    setTTSelectedEmployees,
    ttSummaryForm,
    ttOrderCheckout,
  } = useTTOrder()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()

  const navigate = useNavigate()
  const { addNotification } = useNotification()

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          clearTTOrders()
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTtTab)
        },
        size: 'large',
      },
    ]
  }

  const getSuccessURL = (): string => {
    switch (ttSummaryForm.orderTypeId) {
      case cardOrderTypeEnum.newAndDuplicate:
        return navigationRoutes.ttOrderCompleteSuccess
      case cardOrderTypeEnum.recharge:
        return navigationRoutes.ttRechargeCompleteSuccess
      case cardOrderTypeEnum.discharge:
        return navigationRoutes.ttUnloadCompleteSuccess
      default:
        return navigationRoutes.ttOrderCompleteSuccess
    }
  }

  const getErrorURL = (): string => {
    switch (ttSummaryForm.orderTypeId) {
      case cardOrderTypeEnum.newAndDuplicate:
        return navigationRoutes.ttOrderCompleteError
      case cardOrderTypeEnum.recharge:
        return navigationRoutes.ttRechargeCompleteError
      case cardOrderTypeEnum.discharge:
        return navigationRoutes.ttUnloadCompleteError
      default:
        return navigationRoutes.ttOrderCompleteError
    }
  }

  const confirmOrder = async () => {
    if (ttOrders && ttSummaryForm) {
      startLoading()
      const { data, meta } = await TTOrderService()
        .complete(ttSummaryForm, ttOrders)
        .finally(() => stopLoading())
      stopLoading()
      setTTEmployeesToConfig([])
      setTTSelectedEmployees([])
      meta.status === 'ERROR'
        ? navigate(getErrorURL(), {
            state: {
              success: false,
              errorMessage: meta.messages,
              isExcel: false,
              excel: data?.additionalData || '',
            },
          })
        : navigate(getSuccessURL(), {
            state: { success: true, data: { ...data }, isExcel: false, excel: '' },
          })
    }
  }

  const confirmExcelOrder = async () => {
    startLoading()
    const { data, meta } = await TTOrderService()
      .completeExcelOrder({
        excel: ttOrderCheckout.data?.additionalData
          ? ttOrderCheckout.data?.additionalData
          : '',
        invoiceComment: ttSummaryForm.billReference,
        orderExcelType: GetExcelOrderTypeRequest(
          ttSummaryForm.orderTypeId ? ttSummaryForm.orderTypeId : orderType.Card
        ),
        productCode: edenredProducts.ticketTransporte,
        stopOnWarnings: false,
        orderDate: ttSummaryForm.initialChargeDate,
      })
      .finally(() => stopLoading())
    stopLoading()
    setTTEmployeesToConfig([])
    setTTSelectedEmployees([])
    meta.status === 'ERROR'
      ? navigate(getErrorURL(), {
          state: {
            success: false,
            errorMessage: meta.messages,
            isExcel: true,
            excel: data.additionalData,
          },
        })
      : navigate(getSuccessURL(), {
          state: {
            success: true,
            data: { ...data },
            isExcel: true,
            excel: data.additionalData,
          },
        })
  }

  useEffect(() => {
    setCancelModalTitle(t(TGDOrderTranslation.summaryAssignment.cancelModal.title))
    setCancelModalDescription(
      t(TGDOrderTranslation.summaryAssignment.cancelModal.description)
    )

    if (!ttOrderCheckout || (ttOrderCheckout && !ttOrderCheckout.data))
      navigate(navigationRoutes.ttOrderSummary)
  }, [])

  return {
    setShowCancelModal,
    showCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    showSaveCloseModal,
    setShowSaveCloseModal,
    ttOrderCheckout,
    ttSummaryForm,
    confirmExcelOrder,
    confirmOrder,
  }
}
