import { ChangeEvent, MouseEvent, forwardRef, useImperativeHandle, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreDivider,
  OreErrorText,
  OreFormGroup,
  OreHeading,
  OreInput,
  OreMessageBar,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreRadioButton,
  OreText,
  SvgBin,
  SvgPlus,
  OreStack,
  OreCardContainer,
  OreCardWrapper,
  SvgNavBarNotificactions,
  SvgExternalHealth,
  OreCardTitle,
  OreSwitch,
  OreIcon,
} from '@runroom/oreneta'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { forms } from 'src/domain/translations'
import {
  ExternalHealthForm,
  useConfigureExternalHealthInsuranceController,
  ConfigureModalActions,
} from 'src/Flex/Products/ui/product-configuration'
import { currency } from 'src/domain/enum'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { decimalValueInput } from 'src/core/helpers'
import { Divider } from 'src/presentation/layout'

export const ConfigureExternalHealthInsuranceModalForm =
  forwardRef<ConfigureModalActions>((_, ref) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const [isWarning, setIsWarning] = useState(false)
    const [forceDelete, setForceDelete] = useState(false)
    const {
      form: {
        control,
        handleSubmit,
        register,
        formState,
        clearErrors,
        getFieldState,
        setValue,
        getValues,
      },
      load,
      save,
      saveChanges,
      deletePolicy,
      isGlobal,
    } = useConfigureExternalHealthInsuranceController()
    const { errors } = handleErrors(formState, getFieldState)
    const { fields, append, remove } = useFieldArray({
      control: control,
      name: 'policies',
    })

    const close = (): void => {
      setOpen(false)
    }

    const saveAndClose = (event: MouseEvent): void => {
      event.preventDefault()

      handleSubmit(async (data: ExternalHealthForm) => {
        const result = await save(data)

        if (result) {
          setOpen(false)
        }
      })()
    }

    const savePolicyChanges = (event: MouseEvent, index: number): void => {
      event.preventDefault()
      handleSubmit(async (data: ExternalHealthForm) => {
        const result = await saveChanges({
          company: data.policies[index].company,
          id: data.policies[index].id,
          monthlyLimit: data.policies[index].monthlyPrice,
          policyName: data.policies[index].policyName,
          arePhoneRequired: data.policies[index].arePhoneRequired === 'yes',
          areAddressRequired: data.policies[index].areAddressRequired === 'yes',
        })
        if (result) {
          setValue(`policies.${index}.isWarningMonthlyPrice`, false)
          setValue(`policies.${index}.isWarningPolicyName`, false)
          setValue(`policies.${index}.isWarningCompany`, false)
          setValue(`policies.${index}.isWarningArePhoneRequired`, false)
          setValue(`policies.${index}.isWarningAreAddressRequired`, false)
          setIsWarning(false)
        }
      })()
    }
    const forceDeletePolicy = (event: MouseEvent, index: number): void => {
      event.preventDefault()
      setValue(`policies.${index}.forceDelete`, true)
      setForceDelete(!forceDelete)
    }

    const deletePolicyToApi = (event: MouseEvent, index: number): void => {
      event.preventDefault()
      handleSubmit(async (data: ExternalHealthForm) => {
        const result = await deletePolicy(data.policies[index].id)
        if (result) {
          remove(index)
        }
      })()
    }

    useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
      open: (): void => {
        load().then(() => {
          setOpen(true)
        })
      },
      close,
    }))
    return (
      <OreModal open={isOpen}>
        <OreModalBox size="large" handleOnClose={() => setOpen(false)}>
          <form>
            <OreModalBoxBody noGap>
              <OreCardContainer hasShadow={false}>
                <OreCardTitle
                  icon={
                    <OreIcon
                      size="large"
                      icon={<SvgExternalHealth />}
                      tone="flex-products"
                    />
                  }
                  title={t(flexProductsTranslations.configure.healthExternal.title)}
                />
                <Divider marginTop="1rem" marginBottom="2rem" />
                <OreStack placeContent="stretch" space="large">
                  <OreStack space="large">
                    <OreStack placeItems="start" space="medium">
                      <OreText tone="neutral-600">
                        {t(flexProductsTranslations.configure.common.collectivesWarning)}
                        {fields.length === 1
                          ? ` ` +
                            t(
                              flexProductsTranslations.configure.healthExternal
                                .no_remove_last
                            )
                          : null}
                      </OreText>
                      {fields.map((field, index) => {
                        const arePhoneRequiredErrors = errors(
                          `policies.${index}.arePhoneRequired`
                        )
                        const areAddressRequiredErrors = errors(
                          `policies.${index}.areAddressRequired`
                        )
                        const { onChange, ...rest } = register(
                          `policies.${index}.monthlyPrice`
                        )

                        const handleMonthlyLimitChange = (
                          event: ChangeEvent<HTMLInputElement>
                        ): void => {
                          event.preventDefault()

                          if (Number(event.target.value) % 1 !== 0) {
                            setValue(
                              `policies.${index}.monthlyPrice`,
                              Number(decimalValueInput(event.target.value))
                            )
                          }
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.monthlyPrice ===
                            Number(decimalValueInput(event.target.value))
                            ? setValue(`policies.${index}.isWarningMonthlyPrice`, false)
                            : setValue(`policies.${index}.isWarningMonthlyPrice`, true)
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                        }

                        const handlePolicyNameChange = (
                          event: ChangeEvent<HTMLInputElement>
                        ): void => {
                          event.preventDefault()
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.policyName ===
                            event.target.value
                            ? setValue(`policies.${index}.isWarningPolicyName`, false)
                            : setValue(`policies.${index}.isWarningPolicyName`, true)
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                        }

                        const handleCompanyNameChange = (
                          event: ChangeEvent<HTMLInputElement>
                        ): void => {
                          event.preventDefault()
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.company ===
                            event.target.value
                            ? setValue(`policies.${index}.isWarningCompany`, false)
                            : setValue(`policies.${index}.isWarningCompany`, true)
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                        }

                        const handleArePhoneRequiredChange = (
                          event: ChangeEvent<HTMLInputElement>
                        ): void => {
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.arePhoneRequired ===
                            event.target.value
                            ? setValue(
                                `policies.${index}.isWarningArePhoneRequired`,
                                false
                              )
                            : setValue(
                                `policies.${index}.isWarningArePhoneRequired`,
                                true
                              )
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                        }

                        const handleAreAddressRequiredChange = (
                          event: ChangeEvent<HTMLInputElement>
                        ): void => {
                          formState.defaultValues &&
                          formState.defaultValues.policies &&
                          formState.defaultValues.policies[index]?.areAddressRequired ===
                            event.target.value
                            ? setValue(
                                `policies.${index}.isWarningAreAddressRequired`,
                                false
                              )
                            : setValue(
                                `policies.${index}.isWarningAreAddressRequired`,
                                true
                              )
                          setValue(`policies.${index}.forceDelete`, false)
                          setIsWarning(!isWarning)
                          onChange(event)
                        }

                        return (
                          <OreCardContainer
                            key={field.id}
                            hasShadow={false}
                            tone="neutral-100">
                            <OreCardWrapper>
                              <OreStack space="medium">
                                <OreHeading size="headline-md">
                                  {t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .policies.label
                                  )}
                                </OreHeading>
                                <div
                                  style={{
                                    border: 'solid 1px var(--color-neutral-200)',
                                    borderRadius: 'var(--radius-small)',
                                  }}>
                                  {field.contractStateInfo &&
                                  field.contractStateInfo.isInProgress > 0 ? (
                                    <OreCardContainer
                                      hasBorder
                                      hasShadow={false}
                                      tone="neutral-100">
                                      <OreCardWrapper>
                                        <OreStack direction="row" placeItems="center">
                                          <div>
                                            <SvgNavBarNotificactions />
                                          </div>
                                          <div
                                            style={{ color: 'var(--color-neutral-600)' }}>
                                            {t(
                                              flexProductsTranslations.configure
                                                .healthExternal.form.total_contracts.text1
                                            )}
                                            <strong>
                                              {field.contractStateInfo.isInProgress}
                                            </strong>
                                            <strong>
                                              {t(
                                                flexProductsTranslations.configure
                                                  .healthExternal.form.total_contracts
                                                  .text2
                                              )}
                                            </strong>
                                            {t(
                                              flexProductsTranslations.configure
                                                .healthExternal.form.total_contracts.text3
                                            )}
                                          </div>
                                        </OreStack>
                                      </OreCardWrapper>
                                    </OreCardContainer>
                                  ) : null}
                                </div>
                                <input
                                  type="hidden"
                                  {...register(`policies.${index}.id`)}
                                />
                                <OreInput
                                  {...register(`policies.${index}.company`)}
                                  {...errors(`policies.${index}.company`)}
                                  label={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .company.label
                                  )}
                                  onChange={handleCompanyNameChange}
                                  required
                                />
                                <OreInput
                                  {...register(`policies.${index}.policyName`)}
                                  {...errors(`policies.${index}.policyName`)}
                                  label={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .policyName.label
                                  )}
                                  onChange={handlePolicyNameChange}
                                  required
                                />
                                <OreInput
                                  {...rest}
                                  {...errors(`policies.${index}.monthlyPrice`)}
                                  type="number"
                                  startAdornment={currency.euro}
                                  required
                                  legend={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .monthlyImport.legend
                                  )}
                                  label={t(
                                    flexProductsTranslations.configure.healthExternal.form
                                      .monthlyImport.label
                                  )}
                                  onChange={handleMonthlyLimitChange}
                                />
                                <OreStack space="medium">
                                  <OreFormGroup
                                    {...arePhoneRequiredErrors}
                                    direction="row"
                                    label={t(
                                      flexProductsTranslations.configure.healthExternal
                                        .form.phone.label
                                    )}
                                    required>
                                    <OreRadioButton
                                      {...register(`policies.${index}.arePhoneRequired`)}
                                      label={t(forms.labels.yes)}
                                      value="yes"
                                      hasError={arePhoneRequiredErrors.hasError}
                                      onChange={handleArePhoneRequiredChange}
                                    />
                                    <OreRadioButton
                                      {...register(`policies.${index}.arePhoneRequired`)}
                                      label={t(forms.labels.no)}
                                      value="no"
                                      hasError={arePhoneRequiredErrors.hasError}
                                      onChange={handleArePhoneRequiredChange}
                                    />
                                  </OreFormGroup>
                                  <OreFormGroup
                                    {...areAddressRequiredErrors}
                                    direction="row"
                                    label={t(
                                      flexProductsTranslations.configure.healthExternal
                                        .form.address.label
                                    )}
                                    required>
                                    <OreRadioButton
                                      {...register(
                                        `policies.${index}.areAddressRequired`
                                      )}
                                      label={t(forms.labels.yes)}
                                      value="yes"
                                      hasError={areAddressRequiredErrors.hasError}
                                      onChange={handleAreAddressRequiredChange}
                                    />
                                    <OreRadioButton
                                      {...register(
                                        `policies.${index}.areAddressRequired`
                                      )}
                                      label={t(forms.labels.no)}
                                      value="no"
                                      hasError={areAddressRequiredErrors.hasError}
                                      onChange={handleAreAddressRequiredChange}
                                    />
                                  </OreFormGroup>
                                </OreStack>
                                {(getValues().policies[index]
                                  .isWarningAreAddressRequired ||
                                  getValues().policies[index].isWarningArePhoneRequired ||
                                  getValues().policies[index].isWarningCompany ||
                                  getValues().policies[index].isWarningMonthlyPrice ||
                                  getValues().policies[index].isWarningPolicyName) &&
                                !field.isNew &&
                                field.contractStateInfo?.isInProgress &&
                                field.contractStateInfo.isInProgress > 0 ? (
                                  <>
                                    <OreMessageBar color="warning" icon>
                                      {t(
                                        flexProductsTranslations.configure.healthExternal
                                          .update_warning
                                      )}
                                    </OreMessageBar>
                                    <OreDivider />
                                    <OreStack placeContent="end">
                                      <OreButton
                                        type="submit"
                                        onClick={event => savePolicyChanges(event, index)}
                                        size="small"
                                        category="secondary">
                                        {t(
                                          flexProductsTranslations.configure
                                            .healthExternal.save_changes
                                        )}
                                      </OreButton>
                                    </OreStack>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {!getValues().policies[index]
                                  .isWarningAreAddressRequired &&
                                  !getValues().policies[index]
                                    .isWarningArePhoneRequired &&
                                  !getValues().policies[index].isWarningCompany &&
                                  !getValues().policies[index].isWarningMonthlyPrice &&
                                  !getValues().policies[index].isWarningPolicyName &&
                                  !field.isNew &&
                                  !getValues().policies[index].forceDelete && (
                                    <>
                                      <OreDivider />
                                      <OreStack placeContent="start">
                                        <OreButton
                                          icon={<SvgBin />}
                                          category="tertiary"
                                          onClick={event =>
                                            forceDeletePolicy(event, index)
                                          }>
                                          {t(
                                            flexProductsTranslations.configure
                                              .healthExternal.delete_policy
                                          )}
                                        </OreButton>
                                      </OreStack>
                                    </>
                                  )}
                                {getValues().policies[index].forceDelete && (
                                  <>
                                    {field.contractStateInfo &&
                                    field.contractStateInfo.isInProgress ? (
                                      <OreMessageBar color="error" icon>
                                        <b>
                                          {t(
                                            flexProductsTranslations.configure
                                              .healthExternal.force_delete_policy_message1
                                          )}
                                        </b>
                                        <dl>
                                          <dd>
                                            -{' '}
                                            {t(
                                              flexProductsTranslations.configure
                                                .healthExternal
                                                .force_delete_policy_message2
                                            )}
                                            {field.contractStateInfo.isInProgress}
                                            {t(
                                              flexProductsTranslations.configure
                                                .healthExternal
                                                .force_delete_policy_message3
                                            )}
                                          </dd>
                                          <dd>
                                            -{' '}
                                            {t(
                                              flexProductsTranslations.configure
                                                .healthExternal
                                                .force_delete_policy_message4
                                            )}
                                          </dd>
                                          <dd>
                                            -{' '}
                                            {t(
                                              flexProductsTranslations.configure
                                                .healthExternal
                                                .force_delete_policy_message5
                                            )}
                                          </dd>
                                        </dl>
                                      </OreMessageBar>
                                    ) : (
                                      <OreMessageBar color="warning" icon>
                                        {t(
                                          flexProductsTranslations.configure
                                            .healthExternal.force_delete_policy_message6
                                        )}
                                      </OreMessageBar>
                                    )}
                                    <OreDivider />
                                    <OreStack placeContent="start">
                                      <OreButton
                                        icon={<SvgBin />}
                                        category="tertiary"
                                        style={{ color: 'red' }}
                                        onClick={event =>
                                          deletePolicyToApi(event, index)
                                        }>
                                        {t(
                                          flexProductsTranslations.configure
                                            .healthExternal.force_delete_policy
                                        )}
                                      </OreButton>
                                    </OreStack>
                                  </>
                                )}

                                {fields.length > 1 && field.isNew ? (
                                  <>
                                    <OreDivider />
                                    <OreButton
                                      style={{ placeSelf: 'start' }}
                                      icon={<SvgBin />}
                                      category="tertiary"
                                      size="small"
                                      onClick={() => remove(index)}>
                                      {t(
                                        flexProductsTranslations.configure.healthExternal
                                          .form.actions.delete
                                      )}
                                    </OreButton>
                                  </>
                                ) : null}
                              </OreStack>
                            </OreCardWrapper>
                          </OreCardContainer>
                        )
                      })}
                      {formState.errors.policies ? (
                        <OreErrorText>{formState.errors.policies.message}</OreErrorText>
                      ) : null}
                      <OreButton
                        icon={<SvgPlus />}
                        category="primary"
                        size="small"
                        onClick={() => {
                          append({
                            id: '',
                            policyName: '',
                            company: '',
                            monthlyPrice: undefined,
                            isNew: true,
                            forceDelete: false,
                            arePhoneRequired: 'no',
                            areAddressRequired: 'no',
                          })
                          clearErrors('policies')
                        }}>
                        {t(
                          flexProductsTranslations.configure.healthExternal.form.actions
                            .add
                        )}
                      </OreButton>
                    </OreStack>
                    {isGlobal && (
                      <div className="pt-1">
                        <OreStack>
                          <OreHeading size="headline-md">
                            {t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.title
                            )}
                          </OreHeading>
                          <OreText>
                            {t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.subtitle
                            )}
                          </OreText>
                          <OreSwitch
                            {...register('active')}
                            label={t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.activate
                            )}
                            secondLabel={t(
                              flexProductsTranslations.configure.ticketRestaurant.form
                                .activate.deactivate
                            )}
                          />
                        </OreStack>
                      </div>
                    )}
                    <OreText>{t(forms.errors.fieldsRequired)}</OreText>
                  </OreStack>
                </OreStack>
              </OreCardContainer>
            </OreModalBoxBody>
            <OreModalBoxFooter>
              <OreButton onClick={close} size="small" category="secondary">
                {t(forms.buttons.cancel)}
              </OreButton>
              <OreButton
                type="submit"
                onClick={saveAndClose}
                size="small"
                category="primary">
                {t(flexProductsTranslations.configure.common.submit)}
              </OreButton>
            </OreModalBoxFooter>
          </form>
        </OreModalBox>
      </OreModal>
    )
  })
