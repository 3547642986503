import { useTranslation } from 'react-i18next'
import { LogaltySuccess } from '../../Logalty/ui/molecules'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { roleStandarizationTranslation } from 'src/domain/translations/autoenrollment/autoEnrollmentTranslate'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'
import { LogaltyResponseStatus } from '../../Logalty/domain'

export const SuccessScreen = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userHomePage } = useAuth()

  const {
    logalty: { signStatus, setSignStatus },
    previousStep,
  } = useRoleStandarization()

  const handleReturn = (): void => {
    setSignStatus(LogaltyResponseStatus.Pending)
    previousStep()
    navigate(userHomePage)
  }

  return (
    <LogaltySuccess
      title={t(roleStandarizationTranslation.acceptancePage.successScreen.title)}
      description={t(
        roleStandarizationTranslation.acceptancePage.successScreen.description
      )}
      buttonLabel={t(
        roleStandarizationTranslation.acceptancePage.successScreen.buttonLabel
      )}
      signStatus={signStatus}
      returnCallback={handleReturn}
    />
  )
}
