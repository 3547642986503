import { useEffect, useCallback } from 'react'

const TokenManager = (): null => {
  const handleStorageChange = useCallback((e: StorageEvent) => {
    if (localStorage.getItem('token') !== localStorage.getItem('old_token')) {
      localStorage.setItem('old_token', localStorage.getItem('token') || '')
      //do not reload current tab
      if (!document.hasFocus()) {
        window.location.replace('/')
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange)
  }, [handleStorageChange])

  return null
}

export default TokenManager
