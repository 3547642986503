import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { OreHeading } from '@runroom/oreneta'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useModal, useNotificationHandler } from '../../../../hooks'
import type {
  CardOrderProtocol,
  SaveCardOrder,
} from '../../../../../domain/protocols/CardOrderProtocol'
import { edenredProducts, MetaStatusCodes } from '../../../../../domain/enum'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import useCardOrderHomeSchema from '../../../../validations/useCardOrderHomeSchema'
import { CardOrderDates } from '../molecules/CardOrderDates'
import CardOrderReferenceAndTerms from '../molecules/CardOrderReferenceAndTerms'
import {
  cardOrderService,
  getOrderContextType,
  getUrlCheckoutByCardOrderType,
  hasRechargeOrDischarge,
  validateDate,
} from '../../../../../core/services'
import { MessageBar } from '../../../Edenred'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { useEffect, useState } from 'react'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { OrderFooter } from '../../cardOrderForm/molecules'
import { useNavigate } from 'react-router-dom'
import { CardOrderSaveAndClose } from '../../cardOrderSaveAndClose'
import { cardOrderFormTranslation } from 'src/domain/translations'
import { CardOrderResumeModel, MessagesModel, MetaModel } from 'src/domain/models'
import { ModalSpinner } from 'src/Flex/Shared'
import { orderValidationsTranslations } from 'src/Flex/Orders/ui/order-validation'
import { use } from 'i18next'

export const CardOrderHomeForm = (props: {
  setErrorData?: React.Dispatch<React.SetStateAction<MessagesModel[] | undefined>>
  setExcelError?: React.Dispatch<React.SetStateAction<string>>
}) => {
  const { t } = useTranslation()
  const {
    orders,
    orderExcel,
    setOrderExcel,
    setCardOrderData,
    setCardOrderCheckout,
    cardOrderData,
    isOrderExcel,
    resetOrderContextAs,
  } = useCardOrder()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()
  const { notificationHandler } = useNotificationHandler()
  const { startLoading, stopLoading } = useLoader()
  const { cardOrderHomeformSchema } = useCardOrderHomeSchema()
  const [errorMessage, setStateErrorMessage] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const navigate = useNavigate()
  const showDates = hasRechargeOrDischarge(orders, orderExcel)

  const methods = useForm<SaveCardOrder>({
    resolver: yupResolver(cardOrderHomeformSchema),
    mode: 'all',
    defaultValues: {
      initialChargeDate: validateDate(cardOrderData?.initialChargeDate),
      expiredDate: validateDate(cardOrderData?.expiredDate),
      billReference: cardOrderData ? cardOrderData.billReference : '',
      acceptConditions: false,
      orderName: '',
      reminderDate: '',
    },
  })

  const validateCardOrder = async () =>
    await methods.trigger([
      'initialChargeDate',
      'expiredDate',
      'acceptConditions',
      'billReference',
    ])

  const submitCardOrder = async () => {
    const orderContext = getOrderContextType(orders)
    resetOrderContextAs(orderContext)
    const submitCardOrderFormValidation = await validateCardOrder()
    if (submitCardOrderFormValidation) {
      const payload: CardOrderProtocol = {
        productCode: edenredProducts.ticketRestaurant,
        cardOrders: orders,
      }
      setShowSpinner(true)
      const url = getUrlCheckoutByCardOrderType(orders)

      const { data, meta } = await cardOrderService().getCheckout(payload, url)
      setShowSpinner(false)
      // Handle API response
      // Success?
      if (meta.status === MetaStatusCodes.SUCCESS && data) {
        // Prepare callback
        const successAction = () => {
          setCardOrderCheckout(data)
          setCardOrderData({
            ...methods.getValues(),
            preOrderId: cardOrderData?.preOrderId,
          })
        }
        // Show notification and callback
        notificationHandler(meta, true, successAction, navigationRoutes.orderCheckout)
      }
      // Error?
      else {
        // get errors from request to populate error table
        GetErrorsFromRequest(data, meta)
        // has any message?
        // if (meta.messages.length > 0) {
        //   const description = meta.messages[0].description
        //   const errorField = meta.messages[0].value
        //   let errorNotification = description
        //   const jsonObject = JSON.parse(errorField)
        //   if (errorField !== null) {
        //     if (jsonObject.document) {
        //       errorNotification += `${t(
        //         cardOrderFormTranslation.employeeDetail.document
        //       )} : ${jsonObject.document}`
        //     } else {
        //       errorNotification += ': ' + errorField
        //     }
        //     setStateErrorMessage(errorNotification)
        //   }
        // }
      }
    }
  }

  const GetErrorsFromRequest = (
    data: CardOrderResumeModel | null,
    meta: MetaModel
  ): void => {
    if (
      meta.status == MetaStatusCodes.ERROR &&
      props.setErrorData &&
      props.setExcelError
    ) {
      props.setErrorData(meta.messages)
      props.setExcelError(data?.additionalData || '')
    }
  }

  // request to complete excel orders
  const submitCardOrderExcel = async () => {
    const submitCardOrderFormValidation = await validateCardOrder()
    if (submitCardOrderFormValidation) {
      const orderExcelCopy = { ...orderExcel }
      orderExcelCopy.detail.expiredDate = methods.getValues().expiredDate
      orderExcelCopy.detail.initialChargeDate = methods.getValues().initialChargeDate
      orderExcelCopy.detail.billReference = methods.getValues().billReference
      setOrderExcel(orderExcelCopy)
      setCardOrderCheckout(orderExcelCopy)
      navigate(navigationRoutes.orderCheckout)
    }
  }

  const handleCancelOrder = (): void => {
    navigate(navigationRoutes.myOrders, {
      state: {
        cancelModal: true,
      },
    })
  }

  useEffect(() => {
    setShowSpinner(false)
  }, [])

  return (
    <div className="mt-2">
      <FormProvider {...methods}>
        <OreHeading as="h2" size="headline-md">
          {t(cardOrderTranslation.cardOrderHome.formTitle)}
        </OreHeading>

        <form autoComplete="off">
          {showDates && <CardOrderDates />}
          <CardOrderReferenceAndTerms />
        </form>

        <div className="pt-105">
          <MessageBar color="error" message={errorMessage} />
        </div>

        {isOrderExcel() ? (
          <OrderFooter
            onSaveClick={() => setShowSaveCloseModal(true)}
            onCancelClick={handleCancelOrder}
            onContinueClick={submitCardOrderExcel}
            continueButtonLabel={t(cardOrderTranslation.cardOrderHome.buttonOrder)}
          />
        ) : (
          <OrderFooter
            showSaveAndCloseButton={true}
            onSaveClick={() => setShowSaveCloseModal(true)}
            onCancelClick={handleCancelOrder}
            onContinueClick={submitCardOrder}
            continueButtonLabel={t(cardOrderTranslation.cardOrderHome.buttonOrder)}
            isMainButtonEnabled={orders.length > 0}
          />
        )}
        <ModalSpinner
          description={t(orderValidationsTranslations.spinner.description)}
          title={t(orderValidationsTranslations.spinner.title)}
          show={showSpinner}
        />
      </FormProvider>
      <CardOrderSaveAndClose
        open={showSaveCloseModal}
        handleOnClose={setShowSaveCloseModal}
      />
    </div>
  )
}
