import { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './presentation/i18n/i18n'
import AppRouter from './presentation/routers/AppRouter'
import { AuthProvider } from './presentation/context/auth/AuthProvider'
import { CardOrderProvider } from './presentation/context/cardOrder/CardOrderProvider'
import { AppConfigProvider } from './presentation/context/config/AppConfigProvider'
import { LoaderProvider } from './presentation/context/loader/LoaderProvider'
import { NotificationProvider } from './presentation/context/notification/NotificationProvider'
import { UserProvider } from './presentation/context/user/UserProvider'
import { UIProvider } from './presentation/context/ui/UIProvider'
import Spinner from './presentation/components/Edenred/spinner/Spinner'

import { createApiUserRepository } from './Flex/User/infrastructure'
import { UserDataProvider } from './Flex/User/ui/context'
import { RoleStandarizationProvider } from './presentation/context/roleStandarization/RoleStandarizationProvider'

function App() {
  return (
    <Suspense fallback={<Spinner />}>
      <AppConfigProvider>
        <BrowserRouter>
          <LoaderProvider>
            <RoleStandarizationProvider>
              <AuthProvider>
                <NotificationProvider>
                  <UserProvider>
                    <UserDataProvider repository={createApiUserRepository()}>
                      <CardOrderProvider>
                        <UIProvider>
                          <AppRouter />
                        </UIProvider>
                      </CardOrderProvider>
                    </UserDataProvider>
                  </UserProvider>
                </NotificationProvider>
              </AuthProvider>
            </RoleStandarizationProvider>
          </LoaderProvider>
        </BrowserRouter>
      </AppConfigProvider>
    </Suspense>
  )
}

export default App
