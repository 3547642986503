import { OreButton, OreHeading, OreStack, OreText } from '@runroom/oreneta'
import Success from '../../../../assets/img/Success.svg'
import { LogaltyResponseStatus } from '../../domain'

interface LogaltySuccessProps {
  title: string
  description: string
  extraDescription?: JSX.Element
  buttonLabel: string
  signStatus: LogaltyResponseStatus
  returnRoute?: string
  returnCallback?: () => void
}

export const LogaltySuccess = ({
  title,
  description,
  extraDescription,
  buttonLabel,
  signStatus,
  returnRoute,
  returnCallback,
}: LogaltySuccessProps): JSX.Element => (
  <OreStack placeContent="center" space="2xlarge">
    <OreStack placeItems="center" space="large">
      <img src={Success} alt="Success" />
      <OreStack space="medium" placeItems="center">
        <OreHeading size="title-sm">{title}</OreHeading>
        <OreText style={{ whiteSpace: 'pre-line' }} align="center">
          {description}
        </OreText>
        {extraDescription && extraDescription}
      </OreStack>
    </OreStack>
    <OreStack placeItems="center" placeContent="center">
      <OreButton
        data-testid="finish"
        category={'primary'}
        onClick={
          returnCallback
            ? (): void => returnCallback()
            : returnRoute
            ? (): string => (window.location.href = returnRoute)
            : (): string => (window.location.href = '/')
        }
        disabled={signStatus === LogaltyResponseStatus.Pending}>
        {buttonLabel}
      </OreButton>
    </OreStack>
  </OreStack>
)
