import {
  OreIconButton,
  OreStack,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgBin,
  SvgEdit,
} from '@runroom/oreneta'
import { useNavigate } from 'react-router-dom'
import { TemplateModel } from 'src/Flex/CommunicationsTemplates/domain'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useTranslation } from 'react-i18next'
import { GetDateFormatted } from 'src/core/helpers'

interface IProps {
  template: TemplateModel
  deleteTemplate: (template: TemplateModel) => void
}

export const TemplatesRow = ({ template, deleteTemplate }: IProps): JSX.Element => {
  const { id, name, type, description, lastModificationDate, isDeleteable } = template
  const navigate = useNavigate()
  const { t } = useTranslation()

  const handleCommunicationTemplateEdit = (): void => {
    navigate(flexNavigationRoutes.flexCommunicationsTemplatesEdit.replace(':id', id))
  }

  return (
    <OreTableRow valign="middle">
      <OreTableCell size="quarter">{name}</OreTableCell>
      <OreTableCell>
        <OreStack space="small">
          <OreText size="text-sm">
            {type === 1
              ? t(flexCommunicationsTemplatesTranslations.table.body.type_pdf)
              : t(flexCommunicationsTemplatesTranslations.table.body.type_email)}
          </OreText>
        </OreStack>
      </OreTableCell>
      <OreTableCell size="half">
        <OreText size="text-sm">{description}</OreText>
      </OreTableCell>
      <OreTableCell size="quarter">
        <OreText size="text-sm">{GetDateFormatted(lastModificationDate)}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="2xsmall" direction="row">
          <OreTooltip
            placement="top"
            text={t(flexCommunicationsTemplatesTranslations.table.body.edit_title)}>
            <OreIconButton icon={<SvgEdit />} onClick={handleCommunicationTemplateEdit} />
          </OreTooltip>
          {isDeleteable ? (
            <OreTooltip
              placement="top"
              text={t(flexCommunicationsTemplatesTranslations.table.body.delete_title)}>
              <OreIconButton
                icon={<SvgBin />}
                onClick={(event): void => {
                  event.preventDefault()
                  deleteTemplate(template)
                }}
              />
            </OreTooltip>
          ) : null}
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
