import React, { useState } from 'react'
import { Table } from '../../../Edenred'
import { CardOrderHomeForm } from '../../cardOrderHome/organism'
import { useUnloadHomeController } from '../hook/useUnloadHomeController'
import { useTranslation } from 'react-i18next'
import { unloadTranslation } from '../../../../../domain/translations'
import { CardOrderHomeFooter } from '../../cardOrderHome/molecules'
import { LeaveOrderPrompt } from 'src/presentation/components/Edenred/navigation'
import { MessagesModel } from 'src/domain/models'

const Container = () => {
  const { rows, header, footer, setRows, tableEmpty, caption } = useUnloadHomeController()
  const { t } = useTranslation()
  return (
    <>
      <Table
        header={header}
        rows={rows}
        setRows={setRows}
        selector={{ buttonTitle: '', description: '', selectors: [], title: '' }}
        footer={footer()}
        tableEmpty={tableEmpty}
        tableBodyValign="middle"
        caption={caption}
        confirmDelete={true}
        confirmDeleteTitle={t(unloadTranslation.tableAction.delete.title)}
        confirmDeleteDescription={t(unloadTranslation.tableAction.delete.description)}>
        <>{rows.length > 0 ? <CardOrderHomeForm /> : <CardOrderHomeFooter />}</>
      </Table>

      <LeaveOrderPrompt />
    </>
  )
}

export default Container
