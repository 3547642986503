import { forwardRef, useImperativeHandle, useState } from 'react'

import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreSelect,
  OreSelectPlaceholder,
  OreStack,
  OreText,
} from '@runroom/oreneta'

import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { useSendEmail } from 'src/Flex/CommunicationsTemplates/ui/templates-send'
import {
  TemplateModel,
  TemplateTargetEnum,
} from 'src/Flex/CommunicationsTemplates/domain'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'

export type SendEmailModalActions = {
  open: (id: string, target: TemplateTargetEnum) => void
}

interface IProps {
  ref: React.Ref<SendEmailModalActions>
  target: TemplateTargetEnum
  actionOnClose?: () => void
}

export const SendEmailModal = forwardRef<SendEmailModalActions, IProps>(
  (props, ref): JSX.Element => {
    const { getAvailableTemplatesById, sendEmailToTarget } = useSendEmail()
    const [open, setOpen] = useState<boolean>(false)
    const { t } = useTranslation()
    const { target, actionOnClose } = props

    const [id, setId] = useState<string>('')
    const [templateId, setTemplateId] = useState<string>('')

    const [availableTemplates, setAvailableTemplates] = useState<
      Partial<TemplateModel>[]
    >([])

    useImperativeHandle(ref, () => ({
      open: async (id: string): Promise<void> => {
        await getAvailableTemplatesById(id, target).then(response => {
          if (response) {
            setId(id)
            setAvailableTemplates(response)
            setOpen(true)
          }
        })
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      await sendEmailToTarget([id], target, templateId).then(response => {
        if (response) {
          actionOnClose && actionOnClose()
          setOpen(false)
        }
      })
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexCommunicationsTemplatesTranslations.sendModal.title, {
                target: t(
                  flexCommunicationsTemplatesTranslations.sendModal.targets.title[
                    target === TemplateTargetEnum.employee ? 'employee' : 'collective'
                  ]
                ),
              })}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(flexCommunicationsTemplatesTranslations.sendModal.subtitle, {
                  target: t(
                    flexCommunicationsTemplatesTranslations.sendModal.targets.subtitle[
                      target === TemplateTargetEnum.employee ? 'employee' : 'collective'
                    ]
                  ),
                })}
              </OreText>
              <OreSelect
                label={t(flexCommunicationsTemplatesTranslations.sendModal.select.label)}
                onChange={(event: any) => setTemplateId(event.target.value)}>
                <OreSelectPlaceholder
                  label={t(
                    flexCommunicationsTemplatesTranslations.sendModal.select.placeholder
                  )}
                />
                {availableTemplates.map(template => (
                  <option key={template.id} value={template.id}>
                    {template.name}
                  </option>
                ))}
              </OreSelect>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              onClick={saveAndClose}
              size="small"
              category="primary"
              disabled={templateId === ''}>
              {t(flexCommunicationsTemplatesTranslations.sendModal.button.label)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
