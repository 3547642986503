import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { OreText } from '@runroom/oreneta'
import { roleStandarizationTranslation } from 'src/domain/translations/autoenrollment/autoEnrollmentTranslate'
import { useTranslation } from 'react-i18next'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'
import { useRoleStandarizationController } from '../hooks/useRoleStandarizationController'
import { Modal } from '../../Edenred'

export const RoleStandarizationModal = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { showModal, setShowModal, modalButtons, downloadPDFFile } =
    useRoleStandarizationController()
  const { user } = useAuth()
  const { file } = useRoleStandarization()
  const acceptancePending = user?.dataControllerAcceptancePending

  if (!acceptancePending || !file) return null

  return (
    <Modal
      handleOnClose={setShowModal}
      open={showModal}
      buttons={modalButtons}
      size="medium"
      title={t(roleStandarizationTranslation.modal.title)}
      closeIcon={false}>
      <>
        {/* <OreHeading align="center" size="headline-md">
          ¡Actualizamos nuestro tratamiento de datos!
        </OreHeading> */}
        <OreText align="left" as="p" size="text-md" tone="neutral">
          {t(roleStandarizationTranslation.modal.description.first)}
        </OreText>
        <OreText align="left" as="p" size="text-md" tone="neutral">
          {t(roleStandarizationTranslation.modal.description.second)}{' '}
          <a
            href="#"
            onClick={e => {
              e.preventDefault()
              downloadPDFFile()
            }}>
            {t(roleStandarizationTranslation.modal.description.third)}
          </a>{' '}
          {t(roleStandarizationTranslation.modal.description.fourth)}
        </OreText>
      </>
    </Modal>
  )
}
