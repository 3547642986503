import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useEffect, useState } from 'react'
import { autoenrollmentService } from 'src/core/services/AutoenrollmentService'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { roleStandarizationTranslation } from 'src/domain/translations/autoenrollment/autoEnrollmentTranslate'
import { useTranslation } from 'react-i18next'
import {
  autoenrollmentStandarizationProcessStatus,
  RoleStandarizationSteps,
} from 'src/domain/enum/autoenrollment'
import { useModalController } from '../../Edenred'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'

interface ControlState {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  modalButtons: PopupButtonModel[]
  downloadPDFFile: () => void
  initRoleStandarizationAcceptance: () => Promise<void>
  roleStandarizationStep: RoleStandarizationSteps
  nextStep: () => void
  previousStep: () => void
}

export const useRoleStandarizationController = (): ControlState => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const { startLoading, stopLoading } = useLoader()
  const numberOfSteps = Object.keys(RoleStandarizationSteps).length / 2
  const [roleStandarizationStep, setRoleStandarizationStep] =
    useState<RoleStandarizationSteps>(RoleStandarizationSteps.SIGNATURE)

  const { file, showModalAndWarning, logalty } = useRoleStandarization()

  const nextStep = (): void => {
    console.log('go to nextStep')
    setRoleStandarizationStep(RoleStandarizationSteps.CONFIRMATION)
    window.scrollTo(0, 0)
  }

  // Not in use
  const previousStep = (): void => {
    setRoleStandarizationStep(RoleStandarizationSteps.SIGNATURE)
    window.scrollTo(0, 0)
  }

  const {
    show: showModal,
    buttons: modalButtons,
    setShow: setShowModal,
    setButtons: setModalButtons,
  } = useModalController()

  const getModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(roleStandarizationTranslation.modal.buttons.reject),
        category: 'secondary',
        onClick: () => setShowModal(false),
        // size: 'large',
      },
      {
        title: t(roleStandarizationTranslation.modal.buttons.accept),
        category: 'primary',
        onClick: initRoleStandarizationAcceptance,
        // size: 'large',
      },
    ]
  }

  const downloadPDFFile = async () => {
    if (!file) return

    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${file}`
    link.download = 'tratamiento-de-datos.pdf'
    link.click()
    link.remove()
  }

  const initRoleStandarizationAcceptance = async (): Promise<void> => {
    startLoading()

    const data = {
      processType: autoenrollmentStandarizationProcessStatus.initiated,
    }

    const response = await autoenrollmentService().BeginDocumentsSigning(data)

    if (
      response.meta.status === MetaStatusCodes.SUCCESS &&
      response.data.signSAML &&
      response.data.signUrl
    ) {
      logalty.setLogalty({
        signUrl: response.data.signUrl,
        signSAML: atob(response.data.signSAML),
      })
      setShowModal(false)
      navigate(navigationRoutes.roleStandarizationAcceptance)
    } else {
      addNotification(response.meta?.messages[0].description, NotificationSeverity.error)
    }

    stopLoading()
  }

  useEffect(() => {
    if (showModalAndWarning) {
      setShowModal(true)
      setModalButtons(getModalButtons())
    }
  }, [showModalAndWarning])

  return {
    showModal,
    setShowModal,
    modalButtons,
    downloadPDFFile,
    initRoleStandarizationAcceptance,
    roleStandarizationStep,
    nextStep,
    previousStep,
  }
}
