import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreCheckbox,
  OreDivider,
  OreList,
  OreListItem,
  OreMessageBar,
  OrePagination,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@runroom/oreneta'
import {
  useOrderValidation,
  OrderValidationRow,
  orderValidationsTranslations,
  useBatchCheckboxes,
  getOrderValidationTableHeaders,
  OrderValidationRowGuarderia,
  OrderValidationBasicForm,
  ForceUpdateOrder,
  OrderValidationEmptyRows,
  OrderValidationErrorStep,
} from 'src/Flex/Orders/ui/order-validation'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { tableSelector } from 'src/domain/translations'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { edenredProducts } from 'src/domain/enum'
import { TableSearcher } from 'src/Flex/Shared/ui/TableSearcher'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { isReadyToValidate } from 'src/Flex/Orders/domain'
import {
  AMOUNTGREATERTHANDEFAULT,
  PENDINGVALIDATIONSAMOUNTEQUALZERO,
} from 'src/domain/constants'
import { ModalSpinner } from 'src/Flex/Shared'

export const OrderValidationStepOne = (props: {
  setStepError: Dispatch<SetStateAction<number>>
}): JSX.Element => {
  const {
    productType,
    orderToValidate,
    form,
    calculate,
    refresh,
    search,
    searchParam,
    rows,
    numberOfRecordsPerPage,
    onPageChange,
    page,
    calculateMetaError,
    updateDate,
    emptyRows,
    showSpinner,
    excelError,
    errorData,
    backToStartFromError,
  } = useOrderValidation()

  const { t } = useTranslation()

  const { count, handleSelectAllClick, indeterminate, massiveSelect } =
    useBatchCheckboxes({
      name: 'orderLines',
      form,
    })
  const productHeaders = getOrderValidationTableHeaders(productType, t)

  if (!orderToValidate?.orderLines) {
    return <OrderValidationEmptyRows />
  }

  const nextMonth = orderToValidate.month ? new Date(orderToValidate.month) : null

  if (nextMonth) {
    nextMonth.setMonth(nextMonth.getMonth() + 1)
  }

  const isReady = isReadyToValidate(orderToValidate)
  const Row =
    productType === edenredProducts.ticketGuarderia
      ? OrderValidationRowGuarderia
      : OrderValidationRow

  return (
    <>
      {!errorData ? (
        <>
          {orderToValidate.canOrderBeUpdate ? (
            <ForceUpdateOrder refresh={refresh} date={updateDate} />
          ) : null}
          {emptyRows ? (
            <form>
              <OreStack space="2xlarge" placeContent="stretch">
                <OreTableContainer>
                  <TableSearcher
                    handleSearch={search}
                    title={t(orderValidationsTranslations.step1.table.title)}
                  />
                  <OreTable>
                    <OreTableHead>
                      <OreTableRow>
                        <OreTableCell as="th">
                          <OreCheckbox
                            name="check"
                            checked={rows.length === 0 ? true : massiveSelect}
                            indeterminate={indeterminate}
                            onChange={handleSelectAllClick}
                            disabled={rows.length === 0}
                          />
                        </OreTableCell>
                        {productHeaders.map((header: HeadersModel) => (
                          <OreTableCell as="th" key={header.key}>
                            {header.label}
                          </OreTableCell>
                        ))}
                      </OreTableRow>
                    </OreTableHead>
                    {isReady && rows.length > 0 ? (
                      <OreTableBody>
                        {rows
                          .slice(
                            numberOfRecordsPerPage * (page - 1),
                            numberOfRecordsPerPage + numberOfRecordsPerPage * (page - 1)
                          )
                          .map(orderLine => (
                            <Row key={orderLine.position} orderLine={orderLine} />
                          ))}
                      </OreTableBody>
                    ) : null}
                  </OreTable>

                  {!isReady || rows.length === 0 ? (
                    <OreTableActions>
                      <OreText size="text-sm" tone="neutral-600">
                        {searchParam !== ''
                          ? t(
                              orderValidationsTranslations.step1.table.noOrdersWithFilters
                            )
                          : t(orderValidationsTranslations.step1.table.noOrders)}
                      </OreText>
                    </OreTableActions>
                  ) : (
                    <OreTableActions>
                      <OreText size="text-xs" tone="neutral-500">
                        {count}/{orderToValidate.orderLines.length}{' '}
                        {t(orderValidationsTranslations.step1.table.selected)}
                      </OreText>
                      <OrePagination
                        displayPageButtons
                        count={rows.length}
                        labelNextPage={t(tableSelector.nextPageLabel)}
                        labelPreviousPage={t(tableSelector.previousPageLabel)}
                        onPageChange={onPageChange}
                        page={page}
                        rowsPerPage={numberOfRecordsPerPage}
                        text={t(tableSelector.pageOf)}
                        legend={t(tableSelector.rowsShowing)}
                      />
                    </OreTableActions>
                  )}
                </OreTableContainer>

                {isReady ? (
                  <OreList bullet>
                    <OreListItem key={'item' + 1}>
                      <OreText>
                        {t(orderValidationsTranslations.forceUpdate.list.text1)}
                      </OreText>
                    </OreListItem>
                    <OreListItem key={'item' + 21}>
                      <OreText>
                        {t(orderValidationsTranslations.forceUpdate.list.text2)}
                        {t(flexProductsTranslations.ids[productType])}
                        {t(orderValidationsTranslations.forceUpdate.list.text3)}
                      </OreText>
                    </OreListItem>
                  </OreList>
                ) : null}
                <OrderValidationBasicForm />
              </OreStack>
              <OreDivider space="larger-top" />
              {calculateMetaError &&
              calculateMetaError.code === AMOUNTGREATERTHANDEFAULT ? (
                <>
                  <OreMessageBar color="error" icon>
                    {t(orderValidationsTranslations.step1.calculate.error1) +
                      calculateMetaError.value +
                      t(orderValidationsTranslations.step1.calculate.error2)}
                  </OreMessageBar>
                  <br />
                </>
              ) : (
                <></>
              )}
              {calculateMetaError &&
              calculateMetaError.code === PENDINGVALIDATIONSAMOUNTEQUALZERO ? (
                <>
                  <OreMessageBar color="error" icon>
                    {t(orderValidationsTranslations.step1.calculate.error3)}
                  </OreMessageBar>
                  <br />
                </>
              ) : (
                <></>
              )}
              <OreStack placeContent="end">
                <OreButton
                  type="submit"
                  category="primary"
                  disabled={count === 0}
                  onClick={form.handleSubmit(calculate)}>
                  {t(orderValidationsTranslations.step1.complete.buttonAction)}
                </OreButton>
              </OreStack>
              <ModalSpinner
                description={t(orderValidationsTranslations.spinner.description)}
                title={t(orderValidationsTranslations.spinner.title)}
                show={showSpinner}
              />
            </form>
          ) : (
            <OrderValidationEmptyRows />
          )}
        </>
      ) : (
        <OrderValidationErrorStep
          errors={errorData}
          excelError={excelError}
          back={backToStartFromError}
        />
      )}
    </>
  )
}
