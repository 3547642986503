import {
  OreButton,
  OreHeading,
  OrePagination,
  OrePill,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  SvgAlert,
} from '@runroom/oreneta'
import ErrorLogo from 'src/presentation/assets/img/Warning.svg'
import { orderValidationsTranslations } from '../orderValidationsTranslations'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { MessagesModel } from 'src/domain/models'
import { ErrorMessagesTable } from 'src/presentation/components/Edenred/errorMessagesTable/ErrorMessagesTable'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { Steps } from '../atoms'

export const OrderValidationError = (props: {
  errors: MessagesModel[]
  back: () => void
  backToHome: () => void
  excelError: string
}): JSX.Element => {
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const pageSize = 8

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  return (
    <>
      <div style={{ margin: '3rem 20% 0 20%' }}>
        <OreStack
          direction="column"
          space="large"
          placeContent="space-between"
          placeItems="center">
          <div>
            <figure>
              <img src={ErrorLogo} alt="" />
            </figure>
          </div>

          <OreStack direction="column" space="medium">
            <header className="width-max">
              <OreHeading as="h1" size="title-sm" align="center">
                {t(orderValidationsTranslations.step3.error.tittle)}
              </OreHeading>
            </header>
            <div>
              <OreText align="center">
                {t(orderValidationsTranslations.step3.error.description1)}
              </OreText>
            </div>
            <div>
              <OreText align="center">
                {t(orderValidationsTranslations.step3.error.description2)}
              </OreText>
            </div>
          </OreStack>
        </OreStack>
      </div>
      <ErrorMessagesTable
        errorMessages={props.errors}
        back={props.back}
        backToHome={props.backToHome}
        additionalDataExcel={props.excelError}
        inStep={false}
      />
    </>
  )
}
