import {
  beginDocumentSigning,
  getAutoEnrollmentLogaltyStatus,
  getDataControllerContext,
} from 'src/config/constants/endpoints'
import { AutoEnrollmentRepositoryContract } from 'src/core/contracts/autoenrollment/AutoenrollmentRepositoryContract'
import { createQueryString } from 'src/core/helpers'
import {
  autoenrollmentStandarizationProcessStatus,
  AutoEnrollmentTypes,
} from 'src/domain/enum/autoenrollment'
import { HttpModel, MetaModel } from 'src/domain/models'
import { AutoEnrollmentDocumentsModel } from 'src/domain/models/autoenrollment/AutoEnrollmentDefaultConditionsModel'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import {
  LogaltyModel,
  LogaltyResponseStatus,
} from 'src/presentation/components/Logalty/domain'

type ApiLogaltyResponseStatus = {
  data: {
    hasEnded: boolean
    hasEndedSuccesfully: boolean
  }
  meta: MetaModel
}

const computeLogaltyResponseStatus = ({
  data,
}: ApiLogaltyResponseStatus): LogaltyResponseStatus => {
  if (data.hasEnded) {
    return data.hasEndedSuccesfully
      ? LogaltyResponseStatus.Success
      : LogaltyResponseStatus.Error
  }

  return LogaltyResponseStatus.Pending
}

export class AutoEnrollmentRepository implements AutoEnrollmentRepositoryContract {
  async GetDataControllerContext(): Promise<HttpModel<AutoEnrollmentDocumentsModel>> {
    const response = await axiosFetch(getDataControllerContext, HttpMethod.GET)

    if (!response.data) {
      return {
        data: {
          processStatus: autoenrollmentStandarizationProcessStatus.defaultStatus,
          documents: [],
        },
        meta: response.meta,
      }
    }

    return {
      data: response.data,
      meta: response.meta,
    }
  }

  async BeginDocumentsSigning(data: {
    processType: autoenrollmentStandarizationProcessStatus
  }): Promise<HttpModel<LogaltyModel>> {
    const response = await axiosFetch(beginDocumentSigning, HttpMethod.POST, data)

    if (!response.data) {
      return {
        data: {
          signUrl: '',
          signSAML: '',
        },
        meta: response.meta,
      }
    }

    return {
      data: response.data,
      meta: response.meta,
    }
  }

  async GetAutoEnrollmentLogaltyStatus(
    processType: AutoEnrollmentTypes
  ): Promise<HttpModel<LogaltyResponseStatus>> {
    const response = await axiosFetch(
      getAutoEnrollmentLogaltyStatus.concat(
        createQueryString({
          processType: processType.toString(),
        })
      ),
      HttpMethod.GET,
      {}
    )

    if (!response.data) {
      return {
        data: LogaltyResponseStatus.Pending,
        meta: response.meta,
      }
    }

    return {
      data: computeLogaltyResponseStatus(response),
      meta: response.meta,
    }
  }
}
