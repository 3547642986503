import { useTranslation } from 'react-i18next'
import { LogaltyPending } from '../../Logalty/ui/molecules'
import { roleStandarizationTranslation } from 'src/domain/translations/autoenrollment/autoEnrollmentTranslate'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useEffect } from 'react'

export const PendingScreen = (): JSX.Element => {
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()

  useEffect(() => {
    startLoading()

    return () => {
      stopLoading()
    }
  }, [])

  return (
    <LogaltyPending
      description={t(
        roleStandarizationTranslation.acceptancePage.pendingScreen.description
      )}
    />
  )
}
