import { OreHeading, OreSpinner, OreText } from '@runroom/oreneta'
import { Modal } from 'src/presentation/components'

export const ModalSpinner = (props: {
  show: boolean
  title: string
  description: string
}): JSX.Element => {
  return (
    <Modal
      handleOnClose={() => true}
      closeIcon={false}
      open={props.show}
      topChildren={
        <div className="flex justify-center">
          <OreSpinner />
        </div>
      }>
      <>
        <OreHeading size="headline-lg" align="center">
          {props.title}
        </OreHeading>
        <OreText align="center">{props.description}</OreText>
      </>
    </Modal>
  )
}
