import { useTranslation } from 'react-i18next'
import { OreHeading, OreStack, OreText, OreTabs, OreTab } from '@runroom/oreneta'
import { PageWrapper } from 'src/presentation/layout'
import {
  EmployeesAdherenceProvider,
  FlexProductsAdherenceList,
  ProductsAdherenceProvider,
  employeesAdherenceTranslations,
  productAdherenceTranslations,
} from 'src/Flex/Orders/ui/order-adherence'
import { AdherenceTabsEnum } from '../../domain/AdherenceTabsEnum'
import { useEffect, useState } from 'react'
import { FlexEmployeesLastAdherenceList } from './organisms/FlexEmployeesLastAdherenceList'
import { FlexEmployeesPendingAdherenceList } from './organisms/FlexEmployeesPendingAdherenceList'
import { EmployeesPendingAdherenceProvider } from './contexts/EmployeesPendingAdherenceContext'
import { useSearchParams } from 'react-router-dom'
import { queryParam } from 'src/domain/enum'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const FlexOrdersAdherencePage = (): JSX.Element => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const [selectedTab, setSelectedTab] = useState<number>(AdherenceTabsEnum.employees)

  const selectTabByQueryParam = (): void => {
    const TabParam = searchParams.get(queryParam.adherenceTab)
    if (TabParam) {
      const product = Number(TabParam)
      switch (product) {
        case AdherenceTabsEnum.products:
          setSelectedTab(AdherenceTabsEnum.products)
          break
        default:
          setSelectedTab(AdherenceTabsEnum.employees)
          break
      }
    }
    if (TabParam === null) {
      setSelectedTab(AdherenceTabsEnum.employees)
    }
  }

  useEffect(() => {
    selectTabByQueryParam()
  }, [searchParams])

  return (
    <PageWrapper>
      <OreStack space="large">
        <OreHeading as="h1" size="title-md">
          {t(productAdherenceTranslations.title)}
        </OreHeading>
        <OreStack space="medium" placeContent="stretch">
          <OreTabs>
            <OreTab
              handleClick={() => setSelectedTab(AdherenceTabsEnum.employees)}
              active={selectedTab === AdherenceTabsEnum.employees}
              label={t(employeesAdherenceTranslations.tabEmp)}
            />
            <OreTab
              handleClick={() => setSelectedTab(AdherenceTabsEnum.products)}
              active={selectedTab === AdherenceTabsEnum.products}
              label={t(productAdherenceTranslations.tabProd)}
            />
          </OreTabs>

          {selectedTab === AdherenceTabsEnum.employees && (
            <OreStack space="medium" placeContent="stretch">
              <OreText>{t(employeesAdherenceTranslations.subtitleEmployees)}</OreText>
              <EmployeesPendingAdherenceProvider>
                <FlexEmployeesPendingAdherenceList />
              </EmployeesPendingAdherenceProvider>
              <EmployeesAdherenceProvider>
                <FlexEmployeesLastAdherenceList />
              </EmployeesAdherenceProvider>
            </OreStack>
          )}
          {selectedTab === AdherenceTabsEnum.products && (
            <OreStack space="medium" placeContent="stretch">
              <OreText>{t(productAdherenceTranslations.subtitleProducts)}</OreText>
              <ProductsAdherenceProvider>
                <FlexProductsAdherenceList />
              </ProductsAdherenceProvider>
            </OreStack>
          )}
        </OreStack>
        <OreStack placeContent="stretch">
          <VideoTutorial
            title={t(productAdherenceTranslations.videoTutorial.accept)}
            youtubeUrl="https://www.youtube.com/embed/OQQYQX9c7qo?si=H8zqFA2LCTbRqIFN"
          />
          <VideoTutorial
            title={t(productAdherenceTranslations.videoTutorial.check)}
            youtubeUrl="https://www.youtube.com/embed/WH-E4_TTvGI?si=mm_rXF801nzHj8ug"
          />
        </OreStack>
      </OreStack>
    </PageWrapper>
  )
}
