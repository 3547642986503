import UserRepository from '../../infrastructure/repository/myAccount/ClientUserRepository'
import AccountRepository from '../../infrastructure/repository/myAccount/AccountRepository'
import DeliverySiteRepository from '../../infrastructure/repository/deliverySite/DeliverySiteRepository'
import SpendingRuleRepository from '../../infrastructure/repository/spendingRule/SpendingRuleRepository'
import CardOrderRepository from '../../infrastructure/repository/cardOrder/cardOrderRepository'
import { ExcelRepository } from '../../infrastructure/repository/ExcelRepository'
import { InvoiceRepository } from '../../infrastructure/repository/invoice/InvoiceRepository'
import { EmployeesRepository } from '../../infrastructure/repository/employees/EmployeesRepository'
import { GeneralTermsRepository } from '../../infrastructure/repository/generalTerms/GeneralTermsRepository'
import { ProductRepository } from 'src/Flex/Products/infrastructure'
import { EmployeeRepository } from 'src/Flex/Employees/infrastructure'
import { CollectiveRepository } from 'src/Flex/Collectives/infrastructure'
import { DocumentRepository } from 'src/Flex/Employees/infrastructure'
import { HistorySpendingRepository } from 'src/infrastructure/repository/historyReports/HistorySpendingRepository'
import {
  OrdersImputationRepository,
  OrderValidationRepository,
} from 'src/Flex/Orders/infrastructure'
import { ExcelRepository as EmployeesExcelRepository } from 'src/Flex/Employees/infrastructure'
import { OrderAdherenceRepository } from 'src/Flex/Orders/infrastructure'
import { CommunicationsTemplatesRepository } from 'src/Flex/CommunicationsTemplates/infrastructure'
import { PayrollRepository } from 'src/Flex/Payrolls/infrastructure'
import { TasksRepository } from 'src/Flex/TaskManager/infrastructure'
import { ReportingRepository } from 'src/Flex/Reporting/infrastructure'
import { KindergartenRepository } from 'src/infrastructure/repository/kindergarten/KindergartenRepository'
import { TGDOrderRepository } from 'src/infrastructure/repository/TGDOrder/TGDOrderRepository'
import { TGDBasicOrderRepository } from 'src/infrastructure/repository/TGDOrder/TGDBasicOrderRepository'
import { TTOrderRepository } from 'src/infrastructure/repository/TTOrder/TTOrderRepository'
import { AutoEnrollmentRepository } from 'src/infrastructure/repository/autoenrollment/ApiAutoEnrollmentRepository'

const repositories = {
  account: new AccountRepository(),
  user: new UserRepository(),
  deliverySites: new DeliverySiteRepository(),
  spendingRules: new SpendingRuleRepository(),
  cardOrder: new CardOrderRepository(),
  excel: new ExcelRepository(),
  invoice: new InvoiceRepository(),
  employees: new EmployeesRepository(),
  generalTerms: new GeneralTermsRepository(),
  kindergarten: new KindergartenRepository(),
  flexProduct: new ProductRepository(),
  flexOrderAdherence: new OrderAdherenceRepository(),
  flexEmployee: new EmployeeRepository(),
  flexEmployeeExcel: new EmployeesExcelRepository(),
  flexCollective: new CollectiveRepository(),
  flexDocument: new DocumentRepository(),
  flexOrdersImputations: new OrdersImputationRepository(),
  flexOrderValidation: new OrderValidationRepository(),
  history: new HistorySpendingRepository(),
  flexCommunicationsTemplates: new CommunicationsTemplatesRepository(),
  flexPayrolls: new PayrollRepository(),
  flexTasks: new TasksRepository(),
  flexReporting: new ReportingRepository(),
  tgdOrder: new TGDOrderRepository(),
  tgdBasicOrder: new TGDBasicOrderRepository(),
  ttOrder: new TTOrderRepository(),
  autoenrollment: new AutoEnrollmentRepository(),
}

export function RepositoryFactory<T extends keyof typeof repositories>(repository: T) {
  return repositories[repository]
}
