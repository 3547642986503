import { useEffect } from 'react'
import { PageWrapper } from 'src/presentation/layout'

export const CheckStatusPage = (): JSX.Element => {
  useEffect(() => {
    window.parent.postMessage('firm_process_completed', '*')
  }, [])

  return (
    <PageWrapper>
      <></>
    </PageWrapper>
  )
}
