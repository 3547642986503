import { createContext, useContext } from 'react'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { RoleStandarizationSteps } from 'src/domain/enum/autoenrollment'
import { IUseLogaltyFirmController } from 'src/presentation/components/Logalty/ui/hooks'

// Define the shape of the context value
interface RoleStandarizationContextValue {
  file: string
  handleSetFile: (fileContent: string) => void
  showModalAndWarning: boolean
  handleShowModalAndWarning: (show: boolean) => void
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  modalButtons: PopupButtonModel[]
  downloadPDFFile: () => void
  initRoleStandarizationAcceptance: () => Promise<void>
  logalty: IUseLogaltyFirmController
  roleStandarizationStep: RoleStandarizationSteps
  nextStep: () => void
  previousStep: () => void
}

// Create the context
export const RoleStandarizationContext = createContext<RoleStandarizationContextValue>(
  {} as RoleStandarizationContextValue
)

export const useRoleStandarization = () => useContext(RoleStandarizationContext)
