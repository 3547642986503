import { OreHeading, OreText } from '@runroom/oreneta'
import cx from 'classnames'
import { Divider } from '../layout'
import { Fragment } from 'react'
import { RoleStandarizationMessageBar } from '../components/roleStandarization/molecules/RoleStandarizationMessageBar'

interface HeaderProps {
  title: string
  titleSize?:
    | 'title-xl'
    | 'title-lg'
    | 'title-md'
    | 'title-sm'
    | 'headline-lg'
    | 'headline-md'
    | 'headline-sm'
  subtitle?: string
  homeHero?: boolean
  content?: React.ReactNode
  reducedVersion?: boolean
  homeFlex?: boolean
  organisms: React.ReactNode[]
}

export const HomeTemplate = ({
  title,
  titleSize,
  subtitle,
  homeHero,
  content,
  reducedVersion = false,
  homeFlex = false,
  organisms,
}: HeaderProps): JSX.Element => {
  return (
    <>
      <div
        className={cx(
          'hero',
          { 'hero--home': homeHero },
          { 'hero--reduced': reducedVersion },
          { 'hero--homeflex': homeFlex }
        )}>
        <div className="hero__content">
          <OreHeading align="left" as="h1" size={titleSize} tone="neutral">
            <span className="hero__bullet"></span>
            {title}
          </OreHeading>
          {subtitle && (
            <div className="mt-105">
              <OreText>{subtitle}</OreText>
            </div>
          )}
          <div className="mt-3">{content}</div>
        </div>
      </div>

      <div className={cx({ 'pt-3': homeHero && !homeFlex })}>
        {organisms.map((organism, index) => {
          return (
            <Fragment key={index}>
              <RoleStandarizationMessageBar />
              {index === 1 && <Divider marginTop="4rem" marginBottom="4rem" />}
              <div>{organism}</div>
            </Fragment>
          )
        })}
      </div>
    </>
  )
}
