/*********************************************************
 *  - If it's under a url scope is a VIEW
 *  - Everything else is an ACTION (except by navigation
 *    items).
 *  - If and ACTION leads to a VIEW (Route) we can
 *    protect the view with the same action tag.
 *********************************************************/

export enum viewTag {
  // --------- SUB ----------- //
  // My Account
  VIEW_SUB_MYACCOUNT_USERACCESS = 'VIEW_SUB_MYACCOUNT_USERACCESS',

  // Clients
  VIEW_SUB_CLIENTS = 'VIEW_SUB_CLIENTS',

  // My Orders
  VIEW_SUB_MYORDERS = 'VIEW_SUB_MYORDERS',

  // Employees
  VIEW_SUB_EMPLOYEES = 'VIEW_SUB_EMPLOYEES',

  // Ticket Restaurant
  VIEW_SUB_TR_DELIVERYSITE = 'VIEW_SUB_TR_DELIVERYSITE', // works for TT too
  VIEW_SUB_TR_SPENDINGRULES = 'VIEW_SUB_TR_SPENDINGRULES',

  // --------- FLEX ----------- //
  VIEW_FL_HOME = 'VIEW_FL_HOME', // Works as a switch for all Flex
  VIEW_FL_RETRIBUTIONPLATFORM = 'VIEW_FL_RETRIBUTIONPLATFORM', // From Adhesiones to Comunicaciones
}

export enum actionTag {
  ACTION_FL_EMPLOYEES_ADDANDEDIT = 'ACTION_FL_EMPLOYEES_ADDANDEDIT',
  ACTION_FL_EMPLOYEES_LIST_DELETE = 'ACTION_FL_EMPLOYEES_LIST_DELETE',
  ACTION_FL_EMPLOYEES_LIST_EDIT = 'ACTION_FL_EMPLOYEES_LIST_EDIT',
  ACTION_FL_EMPLOYEES_ADDFROMFORM = 'ACTION_FL_EMPLOYEES_ADDFROMFORM',
  ACTION_FL_EMPLOYEES_ADDEDITFROMEXCEL = 'ACTION_FL_EMPLOYEES_ADDEDITFROMEXCEL',
  ACTION_FL_EMPLOYEES_DOWNLOAD_TEMPLATE = 'ACTION_FL_EMPLOYEES_DOWNLOAD_TEMPLATE',
  ACTION_FL_EMPLOYEES_DOWNLOAD_EMPTY_TEMPLATE = 'ACTION_FL_EMPLOYEES_DOWNLOAD_EMPTY_TEMPLATE',
}
