export const flexCommunicationsTemplatesTranslations = {
  title: 'flex.templates.title',
  onboarding: 'flex.templates.onboarding',
  button_create: 'flex.templates.button_create',
  videotutorial: {
    configure: 'flex.templates.videotutorial.configure',
    create: 'flex.templates.videotutorial.create',
  },
  table: {
    header: {
      title: 'flex.templates.table.header.title',
      name: 'flex.templates.table.header.name',
      description: 'flex.templates.table.header.description',
      date: 'flex.templates.table.header.date',
      type: 'flex.templates.table.header.type',
      actions: 'flex.templates.table.header.actions',
    },
    body: {
      edit_title: 'flex.templates.table.body.edit_title',
      delete_title: 'flex.templates.table.body.edit_delete',
      type_email: 'flex.templates.table.body.type_email',
      type_pdf: 'flex.templates.table.body.type_pdf',
    },
    warning: 'flex.templates.table.body.warning',
  },
  configure: {
    title: 'flex.templates.configure.title',
    title_create: 'flex.templates.configure.title_create',
    form: {
      title: 'flex.templates.configure.form.title',
      subtitle: 'flex.templates.configure.form.subtitle',
      send: {
        title: 'flex.templates.configure.form.send.title',
        subtitle: 'flex.templates.configure.form.send.subtitle',
        subtitle_2: 'flex.templates.configure.form.send.subtitle_2',
        button: 'flex.templates.configure.form.send.button',
        label: 'flex.templates.configure.form.send.label',
        placeholder: 'flex.templates.configure.form.send.placeholder',
      },
      description: {
        text: 'flex.templates.configure.form.description.text',
        list: 'flex.templates.configure.form.description.list',
        required: 'flex.templates.configure.form.description.required',
        optional: 'flex.templates.configure.form.description.optional',
      },
      title_template: {
        label: 'flex.templates.configure.form.title_template.label',
        placeholder: 'flex.templates.configure.form.title_template.placeholder',
      },
      description_template: {
        label: 'flex.templates.configure.form.description_template.label',
        placeholder: 'flex.templates.configure.form.description_template.placeholder',
      },
      name: {
        label: 'flex.templates.configure.form.name.label',
        placeholder: 'flex.templates.configure.form.name.placeholder',
      },
      subject: {
        label: 'flex.templates.configure.form.subject.label',
        placeholder: 'flex.templates.configure.form.subject.placeholder',
      },
      button: {
        label: 'flex.templates.configure.form.button.label',
      },
      text: {
        label: 'flex.templates.configure.form.text.label',
      },
      error_vars: 'flex.templates.configure.form.error_vars',
      resetTitle: 'flex.templates.configure.form.resetTitle',
      resetButton: 'flex.templates.configure.form.resetButton',
      downloadButton: 'flex.templates.configure.form.downloadButton',
    },
  },
  deleteModal: {
    title: 'flex.templates.deleteModal.title',
    subtitle: 'flex.templates.deleteModal.subtitle',
    success: 'flex.templates.deleteModal.success',
  },
  sendModal: {
    title: 'flex.templates.sendModal.title',
    subtitle: 'flex.templates.sendModal.subtitle',
    icon: 'flex.templates.sendModal.icon',
    targets: {
      title: {
        employee: 'flex.templates.sendModal.targets.title.employee',
        collective: 'flex.templates.sendModal.targets.title.collective',
      },
      subtitle: {
        employee: 'flex.templates.sendModal.targets.subtitle.employee',
        collective: 'flex.templates.sendModal.targets.subtitle.collective',
      },
    },
    select: {
      label: 'flex.templates.sendModal.select.label',
      placeholder: 'flex.templates.sendModal.select.placeholder',
    },
    button: {
      label: 'flex.templates.sendModal.button.label',
    },
    success: 'flex.templates.sendModal.success',
    multipleSuccess: 'flex.templates.sendModal.multipleSuccess',
  },
}
