import { AdherenceTabsEnum } from 'src/Flex/Orders/domain/AdherenceTabsEnum'
import { edenredProducts } from 'src/domain/enum'

export type Routes = { [key: string]: string }

export const navigationRoutes = {
  edenredContact: 'https://www.edenred.es/contacto/',

  myProducts: '/MisProductos',
  deliverySiteTR: '/MisProductos/PuntosDeEntregaTR',
  deliverySiteTT: '/MisProductos/PuntosDeEntregaTT',

  deliverySiteNewTR: '/MisProductos/PuntosDeEntregaTR/nuevo',
  deliverySiteNewTT: '/MisProductos/PuntosDeEntregaTT/nuevo',

  deliverySiteNewExcelTR: '/MisProductos/PuntosDeEntregaTR/NuevoFichero',
  deliverySiteNewExcelTT: '/MisProductos/PuntosDeEntregaTT/NuevoFichero',

  deliverySiteEditTR: '/MisProductos/PuntosDeEntregaTR/Editar',
  deliverySiteEditTT: '/MisProductos/PuntosDeEntregaTT/Editar',

  deliverySiteEditByIdTR: '/MisProductos/PuntosDeEntregaTR/Editar/:id',
  deliverySiteEditByIdTT: '/MisProductos/PuntosDeEntregaTT/Editar/:id',

  spendingRules: '/MisProductos/PerfilesUso',
  spendingRulesNew: '/MisProductos/PerfilesUso/nuevo',
  spendingRulesEditById: '/MisProductos/PerfilesUso/Editar/:id',

  generalTermsTR: '/MisProductos/CondicionesGeneralesTR',
  generalTermsTT: '/MisProductos/CondicionesGeneralesTT',
  generalTermsTGD: '/MisProductos/CondicionesGeneralesTGD',

  orderHome: '/MisPedidos/Inicio',
  cardOrderHome: '/MisPedidos/Tarjeta/Inicio',
  myOrders: '/MisPedidos',
  myOrdersTgdTab: '/MisPedidos/Inicio?product=' + edenredProducts.ticketGuarderia,
  myOrdersTtTab: '/MisPedidos/Inicio?product=' + edenredProducts.ticketTransporte,
  orderCheckout: '/MisPedidos/ResumenPedido',
  cardOrderEdit: '/MisPedidos/PedidoDeTarjeta/editar',
  cardOrderDuplicated: '/MisPedidos/PedidoDeDuplicado',
  duplicatedCard: '/MisPedidos/Duplicado',
  rechargeCard: '/MisPedidos/Recarga',
  unloadCard: '/MisPedidos/Descarga',
  cardOrderRechargeExcel: '/MisPedidos/RecargaExcel',
  cardOrderUnloadExcel: '/MisPedidos/DescargaExcel',
  rechargeHome: '/MisPedidos/Recarga/Inicio',
  unloadHome: '/MisPedidos/Descarga/Inicio',
  cardOrderDuplicateConfiguration: '/MisPedidos/ConfiguracionDuplicado',
  cardOrderDuplicateConfigurationEdit: '/MisPedidos/ConfiguracionDuplicado/Editar',
  cardOrderRechargeConfiguration: '/MisPedidos/ConfiguracionRecarga',
  cardOrderRechargeConfigurationEdit: '/MisPedidos/ConfiguracionRecarga/Editar',
  cardOrderUnloadConfiguration: '/MisPedidos/ConfiguracionDescarga',
  cardOrderUnloadConfigurationEdit: '/MisPedidos/ConfiguracionDescarga/Editar',
  cardOrderNew: '/MisPedidos/PedidoDeTarjeta/nuevo',
  cardOrderNewExcel: '/MisPedidos/Excel',
  cardOrderExcelHome: '/MisPedidos/ResumenExcel',
  cardOrderExcelAlerts: '/MisPedidos/AlertasExcel',
  cardOrderCompleteError: '/MisPedidos/Error',
  cardOrderCompleteSuccess: '/MisPedidos/PedidoCompletado',

  myOrdersHistory: '/MisPedidos/Historial',

  clients: '/Clientes',
  clientsHome: '/Clientes/Inicio',

  employees: '/Empleados',
  employeesHome: '/Empleados/Inicio',
  employeeProfile: '/Empleados/Empleado/:document',

  invoices: '/Facturas',
  invoicesHome: '/Facturas/Inicio',

  account: '/MiCuenta',
  accountSettings: '/MiCuenta/Configuracion',
  accountUsersAndLicenses: '/MiCuenta/Usuarios',
  accountDeleted: '/MiCuenta/CuentaEliminada',
  accountNotification: '/MiCuenta/Notificaciones',

  historySpending: '/Historial',
  historyTR: '/Historial/TR/Inicio',
  historyTGD: '/Historial/TGD',
  historyTGDHome: '/Historial/TGD/Inicio',
  historyTT: '/Historial/TT',
  historyTTHome: '/Historial/TT/Inicio',

  //TGD Plus
  tgdOrder: '/MisPedidos/TGD',
  tgdOrderForm: '/MisPedidos/TGD/Nuevo',
  tgdOrderFormEdit: '/MisPedidos/TGD/Editar/:id',
  tgdOrderChildForm: '/MisPedidos/TGD/NuevoHijo',
  tgdOrderChildFormEdit: '/MisPedidos/TGD/EditarHijo/:id',
  tgdOrderExcel: '/MisPedidos/TGD/Nuevo/Excel',
  tgdOrderStoppersAndWarnings: '/MisPedidos/TGD/Nuevo/Revision',
  tgdOrderSummary: '/MisPedidos/TGD/Resumen',
  tgdOrderCheckout: '/MisPedidos/TGD/Calculo',
  tgdOrderCompleteError: '/MisPedidos/TGD/Error',
  tgdOrderCompleteSuccess: '/MisPedidos/TGD/PedidoCompletado',
  tgdOrderSelectSingleEmployee: '/MisPedidos/TGD/Empleados',
  tgdOrderSelectMultipleEmployee: '/MisPedidos/TGD/ListadoEmpleados',
  tgdOrderEmployeesConfiguration: '/MisPedidos/TGD/ConfigurarAsignacion',

  //TDG Basic
  tgdBasicOrder: '/MisPedidos/TGDBasic',
  tgdBasicOrderForm: '/MisPedidos/TGDBasic/Nuevo',
  tgdBasicOrderFormEdit: '/MisPedidos/TGDBasic/Editar/:id',
  tgdBasicOrderChildForm: '/MisPedidos/TGDBasic/NuevoHijo',
  tgdBasicOrderChildFormEdit: '/MisPedidos/TGDBasic/EditarHijo/:id',
  tgdBasicOrderSummary: '/MisPedidos/TGDBasic/Resumen',
  tgdBasicOrderCheckout: '/MisPedidos/TGDBasic/Calculo',
  tgdBasicOrderCompleteError: '/MisPedidos/TGDBasic/Error',
  tgdBasicOrderCompleteSuccess: '/MisPedidos/TGDBasic/PedidoCompletado',

  //Movility
  ttOrder: '/MisPedidos/TT',
  ttOrderForm: '/MisPedidos/TT/Nuevo',
  ttOrderFormEdit: '/MisPedidos/TT/Editar/:id',
  ttOrderSummary: '/MisPedidos/TT/Resumen',
  ttOrderCheckout: '/MisPedidos/TT/Calculo',
  ttOrderCompleteError: '/MisPedidos/TT/Error',
  ttOrderCompleteSuccess: '/MisPedidos/TT/PedidoCompletado',
  ttOrderSelectSingleEmployee: '/MisPedidos/TT/Empleados',
  ttOrderSelectMultipleEmployee: '/MisPedidos/TT/ListadoEmpleados',
  ttOrderEmployeesConfiguration: '/MisPedidos/TT/ConfigurarNuevoPedido',
  ttOrderEmployeesConfigurationEdit: '/MisPedidos/TT/EditarDuplicado/:id',

  // Movility - Recharge
  ttRecharge: '/MisPedidos/TT/Recarga',
  ttRechargeSelectMultipleEmployee: '/MisPedidos/TT/Recarga/ListadoEmpleados',
  ttRechargeEmployeesConfiguration: '/MisPedidos/TT/Recarga/ConfigurarRecarga',
  ttRechargeEmployeesConfigurationEdit: '/MisPedidos/TT/Recarga/Editar/:id',
  ttRechargeSummary: '/MisPedidos/TT/Recarga/Resumen',
  ttRechargeCheckout: '/MisPedidos/TT/Recarga/Calculo',
  ttRechargeCompleteError: '/MisPedidos/TT/Recarga/Error',
  ttRechargeCompleteSuccess: '/MisPedidos/TT/Recarga/PedidoCompletado',

  // Movility - Unload
  ttUnload: '/MisPedidos/TT/Descarga',
  ttUnloadSelectMultipleEmployee: '/MisPedidos/TT/Descarga/ListadoEmpleados',
  ttUnloadEmployeesConfiguration: '/MisPedidos/TT/Descarga/ConfigurarDescarga',
  ttUnloadEmployeesConfigurationEdit: '/MisPedidos/TT/Descarga/Editar/:id',
  ttUnloadSummary: '/MisPedidos/TT/Descarga/Resumen',
  ttUnloadCheckout: '/MisPedidos/TT/Descarga/Calculo',
  ttUnloadCompleteError: '/MisPedidos/TT/Descarga/Error',
  ttUnloadCompleteSuccess: '/MisPedidos/TT/Descarga/PedidoCompletado',

  // Movility - Order Excel
  ttOrderExcel: '/MisPedidos/TT/Excel',
  ttOrderExcelSummary: '/MisPedidos/TT/Excel/Resumen',
  ttOrderExcelCheckout: '/MisPedidos/TT/Excel/Calculo',
  ttOrderExcelCompleteError: '/MisPedidos/TT/Excel/Error',
  ttOrderExcelCompleteSuccess: '/MisPedidos/TT/Excel/PedidoCompletado',

  // Movility - New Order Excel
  ttNewOrderExcel: '/MisPedidos/TT/Excel/Nuevo',
  ttNewOrderImportExcel: '/MisPedidos/TT/Excel/Nuevo/Importar',

  // Movility - Recharge Excel
  ttRechargeExcel: '/MisPedidos/TT/Excel/Recarga',
  ttRechargeImportExcel: '/MisPedidos/TT/Excel/Recarga/Importar',
  ttRechargeSummaryExcel: '/MisPedidos/TT/Excel/Recarga/Resumen',
  ttRechargeExcelCheckout: '/MisPedidos/TT/Excel/Recarga/Calculo',

  // Movility - Unload Excel
  ttUnloadExcel: '/MisPedidos/TT/Excel/Descarga',
  ttUnloadImportExcel: '/MisPedidos/TT/Excel/Descarga/Importar',
  ttUnloadSummaryExcel: '/MisPedidos/TT/Excel/Descarga/Resumen',
  ttUnloadExcelCheckout: '/MisPedidos/TT/Excel/Descarga/Calculo',

  // Role Standarization
  roleStandarizationAcceptance: '/MiCuenta/RegularizacionRoles',

  notFound: '/404',
}

export const flexNavigationRoutes = {
  flex: '/Flex',
  flexProducts: '/Flex/Productos',
  flexEmployees: '/Flex/Empleados',
  flexEmployeesExcel: '/Flex/Empleados/:step',

  flexEmployeesCreate: '/Flex/Empleados/CrearEmpleado',
  flexEmployeesEdit: '/Flex/Empleados/EditarEmpleado/:id',
  flexCollectives: '/Flex/Colectivos',
  flexCollectivesCreate: '/Flex/Colectivos/CrearColectivo',
  flexCollectivesEdit: '/Flex/Colectivos/EditarColectivo/:id',
  flexOrdersValidate: '/Flex/ValidarPedido/:productType/:step',
  flexProductsAdherence: '/Flex/AdhesionProductos',
  flexProductsAdherenceProductsTab:
    '/Flex/AdhesionProductos?adherenceTab=' + AdherenceTabsEnum.products,
  flexProductsAdherenceEmployeesTab:
    '/Flex/AdhesionProductos?adherenceTab=' + AdherenceTabsEnum.employees,
  flexCommunicationsTemplates: '/Flex/Comunicaciones',
  flexCommunicationsTemplatesCreate: '/Flex/Comunicaciones/CrearPlantilla',
  flexCommunicationsTemplatesEdit: '/Flex/Comunicaciones/EditarPlantilla/:id',
  flexProductsImputation: '/Flex/Productos/Imputar/:productType/:step',
  flexPayrolls: '/Flex/Nominas',
  flexPayrollsDnD: '/Flex/Nominas/PersonalizaNomina',
}

export const openNavigationRoutes = {
  logaltyCheckStatus: '/external-route/check-status',
  sso: '/loginsso',
}
