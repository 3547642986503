import isEmpty from 'lodash/isEmpty'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { OreButton, OreStack, OreText, SvgPlus } from '@runroom/oreneta'
import { DropItem } from '../atoms/DropItem'
import { flexPayrollsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'
import {
  PayrollColumnNameEnum,
  PayrollCustomModel,
} from 'src/Flex/Payrolls/domain/PayrollCustomModel'
import Eye from '../../../../../presentation/assets/img/eye.svg'
import EyeClosed from '../../../../../presentation/assets/img/eye-closed.svg'
import { SetStateAction } from 'react'

interface Props {
  id: string
  title: string
  allItems: PayrollCustomModel[]
  itemsId: string[]
  columnName: number
  addNewItem: (index: number) => void
  setItemsList: (value: SetStateAction<PayrollCustomModel[]>) => void
  removeNewItem: (itemId: string) => void
}

const DropZone = ({
  id,
  title,
  itemsId,
  allItems,
  addNewItem,
  columnName,
  setItemsList,
  removeNewItem,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        width: '350px',
        minHeight: '130px',
        backgroundColor: '#F1F5F9',
        borderRadius: '1rem',
        boxSizing: 'border-box',
        boxShadow: '0px 0px 0px 0px #cbcbcbcc, 1px 2px 4px -1px #cbcbcbcc',
        display: 'inline-flex',
        flexDirection: 'column',
        position: 'relative',
        whiteSpace: 'normal',
        padding: '8px',
        marginRight: '2rem',
      }}>
      <div style={{ padding: '.5rem 0 1rem 1rem' }}>
        <OreStack direction="row" placeItems="center">
          <OreText size="text-lg" bold tone="neutral-500">
            {title}
          </OreText>
          <img src={columnName === PayrollColumnNameEnum.MY_PAYROLL ? Eye : EyeClosed} />
        </OreStack>
      </div>
      <Droppable droppableId={id} /*key={id}*/>
        {({ innerRef, placeholder }, { isDraggingOver }) => (
          <div
            style={{
              transition: 'background-color 0.2s ease',
              backgroundColor: isDraggingOver ? '#E2E8F0' : '#F1F5F9',
              minHeight: '50px',
              border: itemsId.length <= 0 ? '2px dashed #d9d9d9' : '',
              borderRadius: '5px',
              margin: itemsId.length <= 0 ? '0 1rem 1rem 1rem' : '',
            }}
            ref={innerRef}>
            {itemsId.length > 0 ? (
              itemsId.map((itemId, index: number) => (
                <Draggable
                  key={itemId + 'dropZone' + index}
                  draggableId={itemId}
                  index={index}>
                  {(
                    { draggableProps, dragHandleProps: eventHandlers, innerRef },
                    { isDragging }
                  ) => (
                    <div
                      ref={innerRef}
                      {...draggableProps}
                      {...eventHandlers}
                      /*key={itemId + '' + index}*/
                    >
                      <DropItem
                        index={index}
                        isDragging={isDragging}
                        item={
                          allItems.find(item => item.id == itemId) as PayrollCustomModel
                        }
                        columnName={columnName}
                        setItemsList={setItemsList}
                        removeNewItem={removeNewItem}
                      />
                    </div>
                  )}
                </Draggable>
              ))
            ) : columnName === PayrollColumnNameEnum.HIDE_ITEMS && itemsId.length <= 0 ? (
              <div style={{ padding: '1rem' }}>
                <OreText align="center" size="text-sm" tone="neutral-400">
                  {t(flexPayrollsTranslations.customPayroll.dragDeleteDescription)}
                </OreText>
              </div>
            ) : columnName === PayrollColumnNameEnum.MY_PAYROLL && itemsId.length <= 0 ? (
              <div style={{ padding: '1rem' }}>
                <OreText align="center" size="text-sm" tone="neutral-400">
                  {t(flexPayrollsTranslations.customPayroll.dragAddDescription)}
                </OreText>
              </div>
            ) : (
              <></>
            )}
            {placeholder}
          </div>
        )}
      </Droppable>

      {columnName === PayrollColumnNameEnum.MY_PAYROLL && (
        <div
          style={{
            display: 'flex',
            placeContent: 'center',
            paddingTop: '1rem',
            paddingBottom: '1rem',
          }}>
          <OreButton
            as="button"
            icon={<SvgPlus />}
            onClick={() => addNewItem(columnName)}
            category="secondary"
            size="small">
            {t(flexPayrollsTranslations.customPayroll.addItem)}
          </OreButton>
        </div>
      )}
      {columnName === PayrollColumnNameEnum.HIDE_ITEMS && itemsId.length > 0 && (
        <div
          style={{
            display: 'flex',
            padding: '0 1rem 1rem 1rem',
          }}>
          <OreText size="text-sm" tone="neutral-400">
            {t(flexPayrollsTranslations.customPayroll.dragDelete2Description)}
          </OreText>
        </div>
      )}
    </div>
  )
}

export default DropZone
